import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Checkbox, Radio, Pagination } from 'antd';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxToggle,
} from '../../component/custom-redux-components/index.js';
import CustomModal from '../../core/modal';
import Loader, { Loader2 } from '../../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { Tag, Modal, Tooltip, Avatar, Spin } from 'antd';
import _blank from '../../images/blank-profile.png';
import Autocomplete from 'react-autocomplete';
import ReduxBreathalyzerTimeline from './breathalyzerTimelineModal';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  getBreathalyzerSchedule,
  listBreathalyzerSchedule,
  upsertBreathalyzerSchedule,
  getOneCallTimeSlots,
  appSubscribersUserSearch,
  getMediaVideoUrl,
  breathalyzerPushATest,
} from '../../store/actions';
import { stringDate } from '../../util/date';
import { LoadingOutlined } from '@ant-design/icons';
import PageTitle from '../../Components/PageTitle';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Validator = {
  required: value => (value ? undefined : 'Required'),
  accountName: value =>
    value && !/^.{1,50}$/.test(value) ? ' cannot exceed 50 characters' : undefined,
  routingNumber: value =>
    value && !/^[0-9]{9}$/.test(value) ? ' is a 9 digit numeric Value' : undefined,
  accountNumber: value =>
    value && !/^[0-9]{4,20}$/.test(value)
      ? ' has a minimum of 4 and a maximum of 20 digits'
      : undefined,

  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? ' numeric values only' : undefined,
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return 'has to be greater than Start Date';
    }
  },
};

class Breathalyzer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ScheduleList: [],
    };
  }
  getPatient = item => {
    let data = [item];
    this.setState({
      ScheduleList: data,
    });
  };
  render() {
    return (
      <div className="container-fluid">
        <PageTitle TitleText="Breathalyzer" />
        <div className="content-area ">
          <div className="row top-table-search mt-4 mb-5 align-items-end">
            <div className="col-12 col-md-3 col-lg-3">
              <a className="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Breathalyzer Testing
              </a>
              {/* <h3 class="page-title mb-3 mb-md-0">Breathalyzer Testing</h3> */}
            </div>
          </div>
          <div className="row mb-5 pb-5">
            <div className="col-xs-12 col-md-4">
              <ReduxScheduleBreathalyzer
                initialValues={{
                  repeatType: 'Daily',
                  isRandom: true,
                }}
                ScheduleList={this.state.ScheduleList}
              />
            </div>
            <div className="col-xs-12 col-md-8">
              <ReduxListBreathalyzer getPatient={this.getPatient} />
              {/* <div class="card border-0 ">
                <div class="row card-body enrollment-form">
                  <div class="col-12 text-left mb-3">
                    <h4>List of tests</h4>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ScheduleBreathalyzer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      capacityCount: 4,
      ScheduleList: [],
      searchOption: [],
      isScheduleListRequired: false,
      isScheduleListRequiredMsg: '',
      isDayOfWeekRequired: false,
      isDayOfWeekRequiredMsg: '',
    };
    props.dispatch(getOneCallTimeSlots({}));
  }
  onGroupCapacity = capacityCount => {
    this.state.capacityCount = capacityCount;
  };
  getCapacityCount = e => {
    let inputNum = e.target.value;
    let pat = /^[0-9]{0,1}$/;
    if (inputNum.match(pat) && inputNum > 1) {
      inputNum = parseInt(inputNum);
      this.setState({
        capacityCount: inputNum || 0,
      });
    }
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      appSubscribersUserSearch({
        Json: JSON.stringify({ AppUser: [{ BreathalyzerSearch: value }] }),
      }),
    );
  };
  onSubmit = val => {
    if (this.state.isScheduleListRequired) return;
    if (val.repeatType === 'Weekly' && val.dayOfWeek && val.dayOfWeek.length <= 0) {
      this.setState({
        isDayOfWeekRequired: true,
        isDayOfWeekRequiredMsg: 'Week Days Required',
      });
      return;
    }
    //   Json: "{"BreathalyzerSchedule":[{"BreathalyzerScheduleGuid":"803122DC-5894-4924-AF81-17661FDDCE65","UserGuid":"B7C5C691-5E7A-4AB4-8CF3-75396FE67EFD","StartDate":"10/10/1999","EndDate":"10/10/1999","RepeatFrequency":"Weekly","DaysOfWeek":"1,2","IsRandom":false,"TestsPerDay":3,"TimeSlots":"1,2,3"}]}"
    let timeSlots = [];
    if (!val.isRandom)
      for (let i = 0; i < this.state.capacityCount; i++) {
        timeSlots.push(val[`option${i}`]);
      }

    const { ScheduleList } = this.state;
    let userGuid = null;
    if (ScheduleList && ScheduleList.length > 0) {
      userGuid = ScheduleList.map(item => item.UserGuid).join();
    }
    let data = {
      BreathalyzerSchedule: [
        {
          BreathalyzerScheduleGuid: null,
          UserGuid: userGuid,
          StartDate: stringDate(val.startDate),
          EndDate: stringDate(val.endDate),
          RepeatFrequency: val.repeatType,
          DaysOfWeek: val.repeatType === 'Weekly' ? val.dayOfWeek && val.dayOfWeek.join() : null,
          IsRandom: val.isRandom,
          TestsPerDay: this.state.capacityCount,
          TimeSlots: !val.isRandom ? timeSlots.join() : null,
          StartTimeSlotId: val.startTime || null,
          EndTimeSlotId: val.endTime || null,
        },
      ],
    };
    let BreathalyzerData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(upsertBreathalyzerSchedule(BreathalyzerData));
  };
  componentWillReceiveProps({ isInserted, ScheduleList, searchOption, dayOfWeekValue }) {
    if (dayOfWeekValue && dayOfWeekValue != this.props.dayOfWeekValue) {
      if (dayOfWeekValue.length <= 0) {
        this.setState({
          isDayOfWeekRequired: true,
          isDayOfWeekRequiredMsg: 'Week Days Required',
        });
      } else {
        this.setState({
          isDayOfWeekRequired: false,
          isDayOfWeekRequiredMsg: '',
        });
      }
    }
    if (isInserted && isInserted != this.props.isInserted) {
      this.props.dispatch(reset('BreathalyzerForm'));
      this.setState({
        ScheduleList: [],
        capacityCount: 4,
        searchStr: '',
      });
      this.props.dispatch(listBreathalyzerSchedule({}));
    }
    if (ScheduleList && JSON.stringify(ScheduleList) != JSON.stringify(this.props.ScheduleList)) {
      this.setState({
        ScheduleList: ScheduleList,
      });
    }
    if (searchOption && JSON.stringify(searchOption) != JSON.stringify(this.props.searchOption)) {
      this.setState({
        searchOption: searchOption,
      });
    }
  }
  render() {
    const { handleSubmit, repeatTypeValue, isRandomValue, timeSlots } = this.props;
    const { capacityCount, ScheduleList, searchStr, searchOption } = this.state;
    const weekDays = [
      { label: 'Mo', value: 2, text: 'Monday' },
      { label: 'Tu', value: 3, text: 'Tuesday' },
      { label: 'We', value: 4, text: 'Wednesday' },
      { label: 'Th', value: 5, text: 'Thursday' },
      { label: 'Fr', value: 6, text: 'Friday' },
      { label: 'Sa', value: 7, text: 'Saturday' },
      { label: 'Su', value: 1, text: 'Sunday' },
    ];
    const getTimeSlot = () => {
      let content = [];
      for (let i = 0; i < capacityCount; i++) {
        content.push(
          <div className="col-6" key={i}>
            <div class="form-group mb-2">
              <Field
                name={`option${i}`}
                type="text"
                component={ReduxSelect}
                validate={Validator.required}
                options={timeSlots}
                placeholder="Test Time"
                fieldName={'Test Time'}
              />
            </div>
          </div>,
        );
      }
      return content;
    };
    const clearSelection = item => {
      let stateArr = this.state.ScheduleList;
      let index;
      index = stateArr.indexOf(item);
      stateArr.splice(index, 1);
      this.setState({ ScheduleList: stateArr });
    };

    return (
      <div className="">
        {/* <Loader2 loading={this.props.isFetching} /> */}
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div class="card border-0 ">
            <div class="row card-body ">
              <div class="col-12 text-left mb-3">
                <h4>Enroll new client(s)</h4>
              </div>
              <div class="col-12 text-left mb-3">
                {/* <h6 class="text-uppercase">Select Client(s)</h6> */}
                {/* <h6>
                  <small
                    style={{
                      fontWeight: '600',
                      lineGeight: '16px',
                      color: '#979797',
                    }}
                  >
                    Search client whose tests haven't been scheduled yet
                  </small>
                </h6> */}
                {/* <div class="search-box w-100">
                  <div class="form-group has-search">
                    <input
                      type="text"
                      value={searchStr}
                      className="form-control shadow-sm"
                      placeholder="Search"
                      onChange={this.studentSearch}
                      ref={ref => (this.input = ref)}
                    />
                  </div>
                </div> */}
                <div class="search-box w-100">
                  <div class="form-group has-search searchDiv">
                    <Autocomplete
                      getItemValue={item => `${item.DisplayName}`}
                      // items={SearchOption[TabName].SearchOption.map(item => {
                      //   return {
                      //     label: item.DisplayName,
                      //     ...item,
                      //   };
                      // })}

                      items={searchOption.map(item => {
                        return {
                          label: ` ${item.DisplayName}`,
                          ...item,
                        };
                      })}
                      // isItemSelectable={item => {
                      //   debugger;
                      //   if (item.DisplayName === 'No Result Found') {
                      //     return false;
                      //   } else {
                      //     return true;
                      //   }
                      // }}

                      // renderItem={(item, isHighlighted) => {
                      //   return (
                      //     <div
                      //       key={item[SearchOption[TabName].idKey]}
                      //       style={{
                      //         margin: '5px',
                      //         background: isHighlighted ? 'lightgray' : 'white',
                      //       }}
                      //     >
                      //       {item.label}
                      //     </div>
                      //   );
                      // }}
                      renderItem={(item, isHighlighted) => (
                        <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                          {item.label}
                        </div>
                      )}
                      // value={value}
                      // onChange={e => (value = e.target.value)}
                      // onSelect={val => (value = val)}

                      onChange={this.studentSearch}
                      ref={ref => (this.input = ref)}
                      // onChange={e => {
                      //   this.setState({ searchStr: e.target.value });
                      //   this.props.dispatch(
                      //     SearchOption[TabName].dispatchMethod({ SearchStr: e.target.value }),
                      //   );
                      // }}

                      onSelect={value => {
                        if (searchOption.length > 0) {
                          let searchResult = searchOption.filter(item => {
                            if (item.DisplayName == value) {
                              return item;
                            } else {
                              return null;
                            }
                          });
                          // this.props.setSearchClientValues(searchResult[0]);
                          let current = this.state.ScheduleList;
                          current.push(searchResult[0]);
                          this.setState({
                            searchedClient: searchResult,
                            ScheduleList: current,
                            isScheduleListRequiredMsg: '',
                          });
                        } else {
                          return [];
                        }

                        // this.props.history.push(SearchOption[TabName].pushKey + value);
                      }}
                      value={this.state.searchStr}
                      // wrapperStyle={{ display: 'unset' }}
                      renderInput={props => (
                        <input
                          type="text"
                          {...props}
                          class="form-control shadow-sm search-placeholder"
                          placeholder="Find client(s)"
                          style={{
                            paddingLeft: '30px',
                            background: 'rgb(255, 255, 255)',
                            boxShadow: 'rgba(0, 0, 0, 0.043) 0px 7px 28px',
                            borderRadius: ' 26px',
                          }}
                        />
                      )}
                      menuStyle={{
                        borderRadius: '3px',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: searchStr ? '4px 4px' : '0px 4px',
                        fontSize: '90%',
                        position: 'fixed',
                        overflow: 'auto',
                        maxHeight: '50%',
                        zIndex: 1,
                      }}
                    />
                    {/* <i class="fas fa-search form-control-icon" /> */}
                  </div>
                  <div className="text-right">
                    {this.state.isScheduleListRequired && (
                      <span
                        style={{
                          display: 'block',
                          textAlign: 'right',
                          color: '#ff3b3b',
                          fontSize: '14px',
                          fontFamily: 'AvenirLTStdMedium',
                        }}
                      >
                        {this.state.isScheduleListRequiredMsg}
                      </span>
                    )}
                  </div>
                </div>

                {/* {searchOption && searchOption.length <= 0 && (
                  <div>
                    <span
                      style={{
                        fontWeight: 'bold',
                        lineGeight: '16px',
                        color: '#979797',
                      }}
                    ></span>{' '}
                    No Result Found
                  </div>
                )} */}
              </div>
              <div className="">
                {ScheduleList &&
                  ScheduleList.map(item => {
                    return (
                      <>
                        <div className="d-inline-flex mr-2">
                          <div
                            style={{
                              background: 'aliceblue',
                              borderRadius: '20px',
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar
                              // size="large"
                              className="mr-2"
                              // icon={<i class="fas fa-users"></i>}
                              src={item.ProfileImageUrl || _blank}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                {item.DisplayName}
                              </span>
                            </div>
                            <Tooltip placement="top" title="Remove">
                              <span
                                class="delete-course"
                                style={{ margin: '0px 0.5rem' }}
                                onClick={() => {
                                  clearSelection(item);
                                }}
                              >
                                <i class="fas fa-times" />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="col-12 enrollment-form">
                <div className="row">
                  <div className="col-12">
                    <h6 className="text-uppercase">Duration</h6>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div class="form-group mb-2">
                      <Field
                        name="startDate"
                        type="text"
                        component={ReduxDateInput}
                        errorMessage="Start Date Required"
                        label={'Start Date*'}
                        validate={Validator.required}
                        minDate={new Date()}
                      />
                      <i
                        class="far fa-calendar-alt form-control-icon"
                        style={{ paddingRight: '11px' }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div class="form-group mb-2">
                      <Field
                        name="endDate"
                        type="text"
                        component={ReduxDateInput}
                        // errorMessage="End Date Required"
                        label={'End Date*'}
                        fieldName={'End Date'}
                        // validate={Validator.required}
                        validate={[Validator.required, Validator.endDate]}
                        minDate={new Date()}
                      />
                      <i
                        class="far fa-calendar-alt form-control-icon"
                        style={{ paddingRight: '11px' }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <h6 className="text-uppercase">Repeat</h6>
                  </div>
                  <div className="col-12">
                    <div class="form-group mb-2 custom-radio">
                      <label className="mr-4">
                        Weekly
                        <Field
                          name="repeatType"
                          component={ReduxInput}
                          type="radio"
                          value="Weekly"
                          //   disabled={this.state.isDisable}
                          style={{
                            width: '20px',
                            height: '20px',
                            border: 'solid 1px #bcbcbc',
                            marginRight: '5px',
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="mr-4">
                        Daily
                        <Field
                          name="repeatType"
                          component={ReduxInput}
                          type="radio"
                          value="Daily"
                          //   disabled={this.state.isDisable}
                          style={{
                            width: '20px',
                            height: '20px',
                            border: 'solid 1px #bcbcbc',
                            marginRight: '5px',
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  {repeatTypeValue === 'Weekly' && (
                    <>
                      <div className="weekly-block">
                        <div className="week-days">
                          <div class="col-12">
                            <div
                              style={{
                                flexFlow: 'wrap',
                                display: 'flex',
                              }}
                              className="repeat-weekly"
                            >
                              <Field
                                name="dayOfWeek"
                                component={CheckboxGroup}
                                col={3}
                                widthClassName={'w-75'}
                                options={weekDays}
                                title={true}
                              />
                            </div>
                          </div>
                          <div className="text-right">
                            {this.state.isDayOfWeekRequired && (
                              <span
                                style={{
                                  display: 'block',
                                  textAlign: 'right',
                                  color: '#ff3b3b',
                                  fontSize: '14px',
                                  fontFamily: 'AvenirLTStdMedium',
                                }}
                              >
                                {this.state.isDayOfWeekRequiredMsg}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div class="col-12 col-xl-8 mt-3">
                    <div class="license-quantity d-flex  justify-content-center">
                      <label class="placeholder-label flex-grow-1">Tests per day</label>
                      <div class="quantity-btn flex-grow-1">
                        <div class="numbers-row">
                          <input
                            name={'groupCapacity'}
                            class="number-icon"
                            type="text"
                            min="0"
                            max="3"
                            value={capacityCount}
                            onChange={this.getCapacityCount}
                            //   validate={Validator.minValue}
                            tabindex="0"
                            style={{
                              textAlign: 'center',
                              margin: '0px 5px',
                              height: '32px',
                              width: '56px',
                              fontSize: '16px',
                            }}
                            //   disabled={isDeactivated}
                          />
                          <div
                            style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                            class="add quantity-button"
                            onClick={() => {
                              // if (!isDeactivated)
                              if (capacityCount <= 9) {
                                return this.setState(
                                  { capacityCount: capacityCount + 1 },
                                  this.onGroupCapacity(capacityCount + 1),
                                );
                              } else {
                                return;
                              }
                            }}
                          >
                            +
                          </div>
                          <div
                            style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                            class="remove quantity-button"
                            onClick={() => {
                              const val = capacityCount - 1;
                              if (val < 1) return;
                              // if (!isDeactivated)
                              this.setState(
                                {
                                  capacityCount: val,
                                },
                                this.onGroupCapacity(val),
                              );
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 mt-3 px-xl-0">
                    <div class="form-group mt-1">
                      <label
                        class="custom-control-label"
                        for="Recurring-Appointment"
                        style={{ fontSize: '16px' }}
                      >
                        Random?{' '}
                        <Field
                          name={`isRandom`}
                          type="text"
                          class="custom-control-input not-empty"
                          component={ReduxToggle}
                          // onChange={bool => toogleChange(bool)}
                          // disabled={this.state.isDisable}
                        />
                      </label>
                    </div>
                  </div>

                  {isRandomValue ? (
                    <>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-lg-6 col-xl-6">
                            <div class="form-group mb-2">
                              <Field
                                name="startTime"
                                type="text"
                                component={ReduxSelect}
                                options={timeSlots}
                                placeholder="Start Time"
                                validate={Validator.required}
                                fieldName={'Start Time'}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6 col-xl-6">
                            <div class="form-group mb-2">
                              <Field
                                name="endTime"
                                type="text"
                                component={ReduxSelect}
                                options={timeSlots}
                                placeholder="End Time"
                                validate={Validator.required}
                                fieldName={'End Time'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-12">
                      <div className="row">{getTimeSlot()}</div>
                    </div>
                  )}
                  <div className="col-12">
                    <div class="modal-footer  border-top-0 pt-2">
                      <input
                        type="submit"
                        class="btn black-btn w-100"
                        value={'Schedule Now'}
                        onClick={() => {
                          let isScheduleListRequired = ScheduleList.length <= 0;
                          let isDayOfWeekRequired = false;
                          if (isScheduleListRequired) {
                            this.setState({
                              isScheduleListRequired: isScheduleListRequired,
                              isScheduleListRequiredMsg: 'Please Select Client',
                            });
                          } else if (repeatTypeValue === 'Weekly') {
                            isDayOfWeekRequired = true;
                            this.setState({
                              isDayOfWeekRequired: isDayOfWeekRequired,
                              isDayOfWeekRequiredMsg: 'Week Days Required',
                            });
                          } else {
                            this.setState({
                              isScheduleListRequired: isScheduleListRequired,
                              isScheduleListRequiredMsg: '',
                              isDayOfWeekRequired: isDayOfWeekRequired,
                              isDayOfWeekRequiredMsg: '',
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const schedulemapStateToProps = state => {
  const {
    upsert_breathalyzer_schedule,
    get_one_call_timeSlots,
    app_subscribers_user_search,
  } = state;
  const { data, isFetching, error } = upsert_breathalyzer_schedule;
  const { data: userData } = app_subscribers_user_search;

  let isInserted = false;
  let timeSlots = [];
  let searchOption = [];

  if (data && data.success) {
    isInserted = data.success;
  }
  if (get_one_call_timeSlots.data && get_one_call_timeSlots.data.Advisor) {
    timeSlots = get_one_call_timeSlots.data.Advisor[0].TimeSlots || [];
  }
  if (userData) {
    searchOption = Object.values(userData);
  }
  const repeatTypeValue = formValueSelector('BreathalyzerForm')(state, 'repeatType');
  const isRandomValue = formValueSelector('BreathalyzerForm')(state, 'isRandom');
  const dayOfWeekValue = formValueSelector('BreathalyzerForm')(state, 'dayOfWeek');

  return {
    searchOption: searchOption || [],
    repeatTypeValue,
    isRandomValue,
    timeSlots,
    isInserted,
    isFetching,
    dayOfWeekValue,
  };
};
const ReduxScheduleBreathalyzer = reduxForm({
  form: 'BreathalyzerForm',
  enableReinitialize: true,
})(connect(schedulemapStateToProps)(ScheduleBreathalyzer));

class ListBreathalyzer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewTimelineModal: false,
      minValue: 0,
      maxValue: 10,
      BreathalyzerData: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(listBreathalyzerSchedule({}));
    // this.props.dispatch(
    //   listBreathalyzerSchedule({
    //     Json: JSON.stringify({
    //       BreathalyzerSchedule: [
    //         {
    //           Search: '',
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }
  componentWillReceiveProps({ isPushTest, BreathalyzerDataObj }) {
    if (isPushTest && isPushTest != this.props.isPushTest) {
      this.toggle('Breathalyzer test has been initiated');
      // this.props.dispatch(listBreathalyzerSchedule({}));
    }
    if (
      BreathalyzerDataObj &&
      JSON.stringify(BreathalyzerDataObj) != JSON.stringify(this.props.BreathalyzerDataObj)
    ) {
      let timeSlot = {};
      if (BreathalyzerDataObj.TimeSlots) {
        BreathalyzerDataObj.TimeSlots.split(',').map((item, index) => {
          timeSlot = {
            ...timeSlot,
            [`option${index}`]: parseInt(item),
          };
        });
      }
      let initData = {
        startDate: BreathalyzerDataObj.StartDate,
        endDate: BreathalyzerDataObj.EndDate,
        repeatType: BreathalyzerDataObj.RepeatFrequency,
        dayOfWeek:
          BreathalyzerDataObj.DaysOfWeek &&
          BreathalyzerDataObj.DaysOfWeek.split(',').map(item => {
            return parseInt(item);
          }),
        isRandom: BreathalyzerDataObj.IsRandom,
        startTime: BreathalyzerDataObj.StartTimeSlotId,
        endTime: BreathalyzerDataObj.EndTimeSlotId,
        testsPerDay: BreathalyzerDataObj.TestsPerDay,
        ...timeSlot,
      };
      this.setState({
        BreathalyzerData: BreathalyzerDataObj,
        initData,
      });
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  handleChange = (value, pageSize) => {
    if (value <= 1) {
      this.setState({
        minValue: value,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (value - 1) * pageSize,
        maxValue: value * pageSize,
      });
    }
  };

  viewTimeline = (activeTab, data, isUpdate) => {
    this.setState({
      BreathalyzerData: {
        UserGuid: data.UserGuid,
        DisplayName: data.DisplayName,
        ProfileImageUrl: data.ProfileImageUrl,
      },
      initData: {},
      viewTimelineModal: true,
      UserGuid: data.UserGuid,
      activeTab: activeTab,
    });
    isUpdate &&
      this.props.dispatch(
        getBreathalyzerSchedule({
          Json: JSON.stringify({
            BreathalyzerSchedule: [
              {
                UserGuid: data.UserGuid,
              },
            ],
          }),
        }),
      );
  };
  render() {
    const { BreathalyzerList, getPatient, FilterBy } = this.props;
    const { searchStr, BreathalyzerData } = this.state;
    return (
      <div className="">
        <div class="card border-0 ">
          <div class="row card-body breathalyzer-test-list">
            <div class="col-12 text-left mb-3">
              <h4>Client(s) enrolled</h4>
            </div>
            <div class="col-sm-12 col-lg-8 text-left mb-3">
              <div class="search-box w-100">
                <div class="form-group has-search">
                  <input
                    type="text"
                    value={searchStr}
                    className="form-control shadow-sm"
                    placeholder="Search client(s).."
                    onChange={event => {
                      this.props.dispatch(
                        listBreathalyzerSchedule({
                          Json: JSON.stringify({
                            BreathalyzerSchedule: [
                              {
                                Search: event.target.value,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        searchStr: event.target.value,
                      });
                    }}
                    //   ref={ref => (this.input = ref)}
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <div class="form-group call-schedule">
                <ReduxSelect
                  options={FilterBy}
                  onChange={val => {
                    this.props.dispatch(
                      listBreathalyzerSchedule({
                        Json: JSON.stringify({
                          BreathalyzerSchedule: [
                            {
                              FilterBy: val,
                            },
                          ],
                        }),
                      }),
                    );
                    this.setState({
                      filterBy: val,
                    });
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={this.state.filterBy}
                  placeholder="Filter By"
                />
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <Loader2 loading={this.props.isFetchingList} />
              {BreathalyzerList && BreathalyzerList.length > 0 ? (
                // BreathalyzerList.slice(this.state.minValue, this.state.maxValue).map(
                <>
                  {BreathalyzerList.slice(this.state.minValue, this.state.maxValue).map(
                    (data, index) => {
                      const {
                        DisplayName,
                        NextTest,
                        BreathalyzerTestResult,
                        LastTestResult,
                        ProfileImageUrl,
                      } = data;
                      const { TestDate, ResultClassBlock, ResultClassText, ResultText } =
                        (LastTestResult && LastTestResult[0]) || {};
                      return (
                        <>
                          {data.IsScheduled ? (
                            <div className="test-card row mx-0 mb-3" key={index}>
                              {/* <div className="d-flex justify-content-between"></div> */}
                              <div className="col-xs-12 col-sm-6 col-md-3">
                                <div className="patient">
                                  <div className="patient-profile">
                                    {/* <img src="images/admin-user.jpg" /> */}
                                    <img
                                      src={ProfileImageUrl || _blank}
                                      style={{ objectFit: 'cover' }}
                                    />
                                  </div>
                                  <div className="patient-name">
                                    <span>{DisplayName}</span>
                                    <div style={{ margin: '-5px 0px' }}>
                                      {BreathalyzerTestResult &&
                                        BreathalyzerTestResult.map(item => {
                                          return <span className={item.ResultClassBlock}></span>;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-md-4 pr-0">
                                <div className="last-test">
                                  <div className="d-flex">
                                    <span className="test-name">Last Test</span>
                                    <span
                                      className="view-schedule"
                                      onClick={() => {
                                        this.viewTimeline(0, data, true);
                                      }}
                                    >
                                      View Timeline
                                    </span>
                                  </div>
                                  <div className="patient-name d-flex align-items-center">
                                    <img src="images/icons/grey_calendar.svg" width="18" />
                                    <span className="test-date">{TestDate}</span>
                                    <div style={{ display: 'inline' }}>
                                      <span className={ResultClassBlock}></span>
                                      <span className={ResultClassText}>{ResultText}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-8 col-md-4">
                                <div className="next-test">
                                  <div className="d-flex">
                                    <span className="test-name">Next Test</span>
                                    <span
                                      className="view-schedule"
                                      onClick={() => {
                                        this.viewTimeline(2, data, true);
                                      }}
                                    >
                                      View Schedule
                                    </span>
                                  </div>
                                  <div className="patient-name d-flex align-items-center">
                                    <img src="images/icons/grey_calendar.svg" width="18" />
                                    <span className="test-date">{NextTest}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-1">
                                <div className="download">
                                  <span style={{ cursor: 'pointer' }} title="Push a test now">
                                    {this.props.isFetchingPushTest &&
                                    this.state.pushTestKey === index ? (
                                      <Spin indicator={antIcon} />
                                    ) : (
                                      <img
                                        style={{ maxWidth: 'unset' }}
                                        src="images/icons/breathalyzer.svg"
                                        alt="download"
                                        // width="45"
                                        onClick={() => {
                                          // this.props.dispatch(breathalyzerPushATest({}));
                                          this.setState({
                                            pushTestKey: index,
                                          });
                                          this.props.dispatch(
                                            breathalyzerPushATest({
                                              Json: JSON.stringify({
                                                Breathalyzer: [
                                                  {
                                                    UserGuid: data.UserGuid,
                                                  },
                                                ],
                                              }),
                                            }),
                                          );
                                        }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="test-card row mx-0 mb-3" key={index}>
                              <div className="col-xs-12 col-sm-6 col-md-3">
                                <div className="patient">
                                  <div className="patient-profile">
                                    {/* <img src="images/admin-user.jpg" /> */}
                                    <img
                                      src={ProfileImageUrl || _blank}
                                      style={{ objectFit: 'cover' }}
                                    />
                                  </div>
                                  <div className="patient-name">
                                    <span>{DisplayName}</span>
                                    <div style={{ margin: '-5px 0px' }}>
                                      {BreathalyzerTestResult &&
                                        BreathalyzerTestResult.map(item => {
                                          return <span className={item.ResultClassBlock}></span>;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {LastTestResult && (
                                <div className="col-xs-12 col-sm-6 col-md-4">
                                  <div className="last-test">
                                    <div className="d-flex">
                                      <span className="test-name">Last Test</span>
                                      <span
                                        className="view-schedule"
                                        onClick={() => {
                                          this.viewTimeline(0, data, true);
                                        }}
                                      >
                                        View Timeline
                                      </span>
                                    </div>
                                    <div className="patient-name d-flex align-items-center">
                                      <img src="images/icons/grey_calendar.svg" width="18" />
                                      <span className="test-date">{TestDate}</span>
                                      <div style={{ display: 'inline' }}>
                                        <span className={ResultClassBlock}></span>
                                        <span className={ResultClassText}>{ResultText}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="col-xs-12 col-sm-6 col-md-4">
                                <div className="last-test">
                                  <div className="d-flex">
                                    <span className="test-name">
                                      No tests scheduled for this client
                                    </span>
                                  </div>
                                  <div className="patient-name">
                                    <span
                                      className="view-schedule"
                                      onClick={() => {
                                        // getPatient(data);
                                        this.viewTimeline(2, data, false);
                                      }}
                                    >
                                      Schedule a test
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-1">
                                <div className="download">
                                  <span style={{ cursor: 'pointer' }} title="Push a test now">
                                    {this.props.isFetchingPushTest &&
                                    this.state.pushTestKey === index ? (
                                      <Spin indicator={antIcon} />
                                    ) : (
                                      <img
                                        style={{ maxWidth: 'unset' }}
                                        src="images/icons/breathalyzer.svg"
                                        alt="download"
                                        // width="35"
                                        onClick={() => {
                                          this.setState({
                                            pushTestKey: index,
                                          });
                                          this.props.dispatch(
                                            breathalyzerPushATest({
                                              Json: JSON.stringify({
                                                Breathalyzer: [
                                                  {
                                                    UserGuid: data.UserGuid,
                                                  },
                                                ],
                                              }),
                                            }),
                                          );
                                        }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    },
                  )}
                  <div className="custom-pagination">
                    <Pagination
                      defaultCurrent={1}
                      defaultPageSize={10}
                      showSizeChanger={true}
                      onShowSizeChange={(current, size) => {
                        this.setState({
                          minValue: (current - 1) * size,
                          maxValue: current * size,
                        });
                      }}
                      onChange={this.handleChange}
                      total={(BreathalyzerList && BreathalyzerList.length) || 15}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div class="col-12 text-center mb-3">
                    <h4>No test scheduled</h4>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Modal
          zIndex={100}
          visible={this.state.viewTimelineModal}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose={true}
        >
          <ReduxBreathalyzerTimeline
            initialValues={this.state.initData}
            BreathalyzerData={this.state.BreathalyzerData}
            UserGuid={this.state.UserGuid}
            activeTab={this.state.activeTab}
            onCancel={() => {
              this.setState({
                viewTimelineModal: false,
              });
            }}
            {...this.props}
          />
        </Modal>
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const ReduxListBreathalyzer = connect(state => {
  const { list_breathalyzer_schedule, get_breathalyzer_schedule, breathalyzer_push_test } = state;
  const { data, isFetching } = list_breathalyzer_schedule;
  const {
    data: BreathalyzerScheduleData,
    isFetching: isFetchingBreathalyzer,
  } = get_breathalyzer_schedule;
  const { data: BreathalyzerPushTestData, isFetching: isFetchingPushTest } = breathalyzer_push_test;

  let BreathalyzerList = [];
  let BreathalyzerData = null;
  let FilterBy = [];
  let isPushTest = false;

  if (BreathalyzerScheduleData && BreathalyzerScheduleData.BreathalyzerSchedule) {
    BreathalyzerData = BreathalyzerScheduleData.BreathalyzerSchedule[0] || [];
  }
  if (data && data.BreathalyzerSchedule) {
    BreathalyzerList = data.BreathalyzerSchedule;
  }
  if (data && data.FilterBy) {
    FilterBy = data.FilterBy;
  }
  if (BreathalyzerPushTestData && BreathalyzerPushTestData.success) {
    isPushTest = BreathalyzerPushTestData.success;
  }
  return {
    BreathalyzerList,
    BreathalyzerDataObj: BreathalyzerData,
    isFetchingList: isFetching,
    isFetchingPushTest,
    isFetchingBreathalyzer,
    FilterBy,
    isPushTest,
  };
})(withRouter(ListBreathalyzer));

export default Breathalyzer;
