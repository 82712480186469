import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PageTitle from '../../Components/PageTitle';
import PageBackAction from '../../Components/PageBackAction';
import { ReleaseInsight, CarePredictorGraph, CategoriesRated } from './customScreens';
import SelectCareTeamMember from './customScreens/SelectCareTeamMember';
import FeedbackList from './FeedbackList';
import {
  feedbackFormListGet,
  feedbackFormAnalyticsGet,
  careTeamMemberCombo,
  resetCareTeamMemberCombo,
} from '../../store/actions';

const TherapeuticAlliance = props => {
  const dispatch = useDispatch();
  //   const [feedback, setFeedback] = useState([]);
  const [practitionerInfo, setPractitionerInfo] = useState({});

  const { careTeamMember, feedbackResponse, isFetching } = useSelector(
    ({
      feedback_form_analytics_get: { data, isFetching, error },
      care_team_member_combo: { data: careTeamMemberData },
    }) => {
      return {
        isFetching: isFetching,
        feedbackResponse: data && data.Feedback[0],
        careTeamMember: careTeamMemberData && careTeamMemberData.CareTeam,
      };
    },
    shallowEqual,
  );
  const getFeedbackFormAnalytics = id => {
    dispatch(
      feedbackFormAnalyticsGet({
        JsonInput: JSON.stringify({
          CareTeam: [
            {
              PractitionerId: id || null,
            },
          ],
        }),
      }),
    );
  };
  useEffect(() => {
    getFeedbackFormAnalytics(10);

    dispatch(
      careTeamMemberCombo({
        Json: JSON.stringify({
          CareTeam: [
            {
              StaffTypeId: 2,
            },
          ],
        }),
      }),
    );
    return () => {
      //   dispatch(resetServiceCodeBundleGet({}));
    };
  }, []);
  useEffect(() => {
    // if (feedbackResponse && feedbackResponse.Feedback) {
    //   setFeedback(feedbackResponse.Feedback);
    // }
    if (feedbackResponse && feedbackResponse.Practitioner && feedbackResponse.Practitioner[0]) {
      setPractitionerInfo(feedbackResponse.Practitioner[0]);
    }
  }, [feedbackResponse]);
  const getSelectedMember = val => {
    getFeedbackFormAnalytics(val);
  };
  return (
    <div className="main therapeutic-alliance">
      <PageTitle TitleText="Therapeutic Alliance" />
      <div className="container-fluid">
        <div className="row top-table-search align-items-center pb-2">
          <div className="col-12 col-md-4 col-lg-4">
            <PageBackAction pageBackText="Back" onClick={() => props.history.goBack()} />
            <h3 className="page-title mb-3 mb-md-0">Therapeutic Alliance</h3>
          </div>
          <div className="col-12 col-md-8 col-lg-8">
            <div className="select-care-team">
              {careTeamMember && (
                <SelectCareTeamMember
                  getSelectedMember={getSelectedMember}
                  careTeamMember={careTeamMember}
                />
              )}
            </div>
          </div>
        </div>
        {/* <div className="row mt-2">
          <div className="col-12">
            <ReleaseInsight ReleaseInsightData={practitionerInfo.ReleaseInsight} />
          </div>
        </div> */}
        <div className="row mt-2">
          <div className="col-sm-12 col-md-6 col-lg-8">
            {/* <CarePredictorGraph /> */}
            <ReleaseInsight ReleaseInsightData={practitionerInfo.ReleaseInsight} />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <CategoriesRated CategoryData={practitionerInfo.Category} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <FeedbackList />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TherapeuticAlliance;
