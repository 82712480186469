import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
} from '../../../../store/actions';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import moment from 'moment';
import AntdModal from '../../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { ProcessCombos } from '../../../../store/comboConfig';

import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import { Table, Modal, Tooltip } from 'antd';
import PersistTableSort from '../../../../component/persistTableSort';
import SubmitButton from '../../../../Components/submitButton';

class ServiceCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceCodeList: [],
      ServiceCodeInfo: {},
      createAndEditGroupModal: false,
      codeDetails: '',
      codeModifierId1: '',
      codeModifierId2: '',
      codeModifierId3: '',
      detailDescription: '',
      searchStr: '',
    };
  }
  // need to work on it always return zero for me
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  refreshGrid = () => {
    const { searchStr } = this.state;
    this.setState({
      searchStr,
    });
    this.props.dispatch(
      listServiceCode({
        json: JSON.stringify({
          ServiceCode: [
            {
              Search: searchStr,
            },
          ],
        }),
      }),
    );
    sessionStorage.setItem(
      'appliedFilter',
      JSON.stringify({
        // Filter: filterType && filterType.toString(),
        searchStr: searchStr,
        // startDate,
        // endDate,
        // filterCenterId,
        // FilterCounserlorId,
      }),
    );
  };

  componentDidMount() {
    this.props.dispatch(listServiceCode({}));
    this.props.dispatch(comboCode({}));
    var result = ProcessCombos('UnitOfMeasurement');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  resetcodeIdandModifiers = () => {
    this.setState({
      codeDetails: '',
      codeModifierId1: '',
      codeModifierId2: '',
      codeModifierId3: '',
      ServiceCodeInfo: {},
    });
  };
  componentWillReceiveProps({
    isInserted,
    isRemoved,
    serviceCodeList,
    serviceCodeinfo,
    codeDetails,
    codeId,
    codeModifierValue,
    codeModifierIndex,
  }) {
    if (codeDetails) {
      if (codeDetails) {
        var data = {
          ...this.state.ServiceCodeInfo,
          detailDescription: codeDetails,
          code: codeId,
        };
        // this.setState({ codeDetails, detailDescription: codeDetails, ServiceCodeInfo: data });
        this.setState({
          codeDetails,
          detailDescription: codeDetails,
          ServiceCodeInfo: data,
        });
      } else {
        this.setState({ codeDetails });
      }
    }
    if (codeModifierIndex) {
      if (codeModifierIndex == 'codeModifier1') {
        this.setState({ codeModifierId1: codeModifierValue });
      }
      if (codeModifierIndex == 'codeModifier2') {
        this.setState({ codeModifierId2: codeModifierValue });
      }
      if (codeModifierIndex == 'codeModifier3') {
        this.setState({ codeModifierId3: codeModifierValue });
      }
    }
    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditGroupModal: false });
      this.toggle('Service Code Saved Successfully');
      // this.props.dispatch(resetUpsertServiceCode());
      // this.props.dispatch(reset('serviceCodeForm'));
      // this.setState({
      //   ServiceCodeInfo: {},
      // });
    }
    if (isRemoved && isRemoved != this.props.isRemoved) {
      this.toggle('Service Code Deleted Successfully');
    }
    if (
      serviceCodeList &&
      JSON.stringify(serviceCodeList) != JSON.stringify(this.props.serviceCodeList)
    ) {
      this.setState({
        ServiceCodeList: serviceCodeList,
      });
    }
    if (
      serviceCodeinfo &&
      JSON.stringify(serviceCodeinfo) != JSON.stringify(this.props.serviceCodeinfo)
    ) {
      let data = {
        ServiceCodeId: serviceCodeinfo.ServiceCodeId,
        serviceCodeName: serviceCodeinfo.ServiceCodeName,
        code: serviceCodeinfo.CodeName,
        modifier1: serviceCodeinfo.CodeModifierId1,
        modifier2: serviceCodeinfo.CodeModifierId2,
        modifier3: serviceCodeinfo.CodeModifierId3,
        // codeDetails: serviceCodeinfo.CodeDescription,
        detailDescription: serviceCodeinfo.ServiceCodeDescription,
        UnitOfMeasurementId: serviceCodeinfo.UnitOfMeasurementId,
        // detailDescription: serviceCodeinfo.CodeDescription,
        ShortServiceDescription: serviceCodeinfo.ShortServiceDescription,
        IsPack: serviceCodeinfo.IsPack,
      };
      this.setState({
        codeDetails: serviceCodeinfo.CodeDescription,
        codeModifierId1: serviceCodeinfo.CodeModifierDescription1,
        codeModifierId2: serviceCodeinfo.CodeModifierDescription2,
        codeModifierId3: serviceCodeinfo.CodeModifierDescription3,
        // detailDescription: serviceCodeinfo.ServiceCodeDescription,
        ServiceCodeInfo: data,
      });

      // this.setState({
      //   ServiceCodeInfo: data,
      // });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      ServiceCodeId,
      code,
      modifier1,
      modifier2,
      modifier3,
      detailDescription,
      UnitOfMeasurementId,
      ShortServiceDescription,
      IsPack,
    } = values;
    // let patientId = null;
    // let paramClientId = this.props.clientId;
    // if (paramClientId && paramClientId > 0) {
    //   patientId = paramClientId;
    // }
    const data = {
      ServiceCode: [
        {
          // ClientId: patientId,
          ServiceCodeId: this.state.ServiceCodeInfo.ServiceCodeId || null,
          CodeId: code,
          CodeModifierId1: modifier1,
          CodeModifierId2: modifier2,
          CodeModifierId3: modifier3,
          ServiceCodeDescription: detailDescription,
          UnitOfMeasurementId: UnitOfMeasurementId,
          ShortServiceDescription: ShortServiceDescription,
          IsPack: IsPack,
        },
      ],
    };
    let Data = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertServiceCode(Data));
  }

  render() {
    const tempColumns = [
      {
        title: 'Code',
        dataIndex: 'Code',
        key: 'Code',
        sorter: (a, b) => isComparer(a.Code, b.Code),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Description',
        dataIndex: 'ServiceCodeDescription',
        key: 'ServiceCodeDescription',
        sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Date Added',
        dataIndex: 'DateAdded',
        key: 'DateAdded',
        sorter: (a, b) => isDateComparer(a.DateAddedForSort, b.DateAddedForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Unit of Measure',
        dataIndex: 'UnitOfMeasurementDescription',
        key: 'UnitOfMeasurementDescription',
        sorter: (a, b) =>
          isComparer(a.UnitOfMeasurementDescription, b.UnitOfMeasurementDescription),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        width: 100,
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <div class="tools-grid">
                <div className="mr-5">
                  <span className="start-btn">
                    <Tooltip placement="top" title="Edit">
                      {' '}
                      <img
                        src="images/shape.svg"
                        width="14px"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={event => {
                          this.setState({
                            createAndEditGroupModal: true,
                          });

                          this.props.dispatch(
                            getServiceCode({
                              json: JSON.stringify({
                                ServiceCode: [
                                  {
                                    ServiceCodeId: record.ServiceCodeId,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>
                <div className="">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-Screen-Tool"
                      >
                        <Tooltip placement="top" title="Delete">
                          <p>
                            <i class="far fa-trash-alt"></i>
                          </p>
                        </Tooltip>
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to delete this Service Code?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                deleteServiceCode({
                                  json: JSON.stringify({
                                    ServiceCode: [
                                      {
                                        ServiceCodeId: record.ServiceCodeId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const { ServiceCodeList } = this.state;
    const { isReadOnlyUser } = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid mt-5">
          <div className="">
            <div className="row px-0 mt-5 d-flex align-items-center">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 px-0 align-self-baseline">
                {' '}
                <h4 className="">SERVICE CODES LIST</h4>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5 px-0 d-flex align-items-center">
                <div class="search-box d-inline-flex w-100">
                  <div class="form-group has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2 px-0 d-flex align-items-center align-self-baseline justify-content-end">
                {/* <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  value={'Filter'}
                /> */}
                {!isReadOnlyUser && (
                  <input
                    type="button"
                    style={{ width: '150px' }}
                    class="btn"
                    value={'New Code'}
                    onClick={event => {
                      this.setState({
                        createAndEditGroupModal: true,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* <ReduxServiceCodeForm
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.ServiceCodeInfo}
            ServiceCodeId={this.state.ServiceCodeInfo.ServiceCodeId}
            onCancel={this.props.onCancel}
            Delete={() => {
              this.Removetoggle();
              //this.props.history.push('/manage/courses')
            }}
          /> */}
          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name="service-code-list"
                    // bordered
                    columns={tempColumns}
                    dataSource={ServiceCodeList}
                    size={'middle'}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(listServiceCode({}));
            this.toggle();
            // this.props.history.push('/manage/courses');
            this.props.dispatch(resetUpsertServiceCode());
            this.props.dispatch(reset('serviceCodeForm'));
            this.resetcodeIdandModifiers();
            this.setState({
              ServiceCodeInfo: {},
            });
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(courseDelete({ CourseId: this.getClientId() }));
            // this.props.history.push('/manage/courses');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            // this.props.history.push('/manage/courses');
          }}
        />
        <Modal
          zIndex={100}
          visible={this.state.createAndEditGroupModal}
          footer={null}
          closable={false}
          width={1000}
          destroyOnClose={true}
        >
          <ReduxServiceCodeForm
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.ServiceCodeInfo}
            ServiceCodeId={this.state.ServiceCodeInfo.ServiceCodeId}
            // onCancel={this.props.onCancel}
            onCancel={() => {
              this.resetcodeIdandModifiers();
              this.setState({ createAndEditGroupModal: false });
            }}
            Delete={() => {
              this.Removetoggle();
              //this.props.history.push('/manage/courses')
            }}
            code={this.props.code}
            codeModifier={this.props.codeModifier}
            dispatch={this.props.dispatch}
            codeModifierValue={this.props.codeModifierValue}
            codeModifierIndex={this.props.codeModifierIndex}
            codeDetails={this.state.codeDetails}
            codeModifierId1={this.state.codeModifierId1}
            codeModifierId2={this.state.codeModifierId2}
            codeModifierId3={this.state.codeModifierId3}
            detailDescription={this.state.detailDescription}
            resetcodeIdandModifiers={this.resetcodeIdandModifiers}
            isUpsertFetching={this.props.isUpsertFetching}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const ServiceCodeForm = props => {
  const handleCodeChange = (value, field) => {
    let data;
    switch (field) {
      case 'code':
        data = {
          Code: [
            {
              CodeId: value,
            },
          ],
        };
        break;
      case 'codeModifier1':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      case 'codeModifier2':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      case 'codeModifier3':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      default:
        break;
    }

    let Data = {
      json: JSON.stringify(data),
    };

    props.dispatch(getCodeDescription(Data));
  };

  const {
    handleSubmit,
    onSaveClick,
    disable,
    ServiceCodeId,
    onCancel,
    code,
    codeModifier,
    codeDetails,
    codeModifierValue,
    codeModifierId1,
    codeModifierId2,
    codeModifierId3,
    detailDescription,
    UnitOfMeasurement,
    dispatch,
    isFetchingGetServiceCode,
    isUpsertFetching,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <Loader2 loading={isFetchingGetServiceCode} />
      <div class="modal-header flex-wrap border-bottom-0 ">
        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        <h4 class="modal-title col-12 text-left px-0">
          {ServiceCodeId ? `EDIT` : `ADD NEW`} SERVICE CODE
        </h4>
        {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
      </div>
      <div class="modal-body rcm-panel enrollment-form">
        <div class="row mb-4">
          <div class="col-12 col-md-4 col-lg-4">
            {/* <div class="form-group">
              <Field
                name={`code`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'code'}
                disabled={disable}
                options={code}
                placeholder="Code"
                onChange={value => {
                  handleCodeChange(value, 'code');
                }}
              />
            </div> */}
            <div class="form-group">
              <Field
                name={`code`}
                type="text"
                component={ReduxAutoComplete}
                options={code}
                label={'Code'}
                onChange={event => {
                  let data = '';
                  if (typeof event == 'string' && event !== '') {
                    data = event;
                  } else if (typeof event == 'object') {
                    data = event.target.value;
                  }
                  dispatch(
                    comboCode({
                      json: JSON.stringify({
                        Code: [
                          {
                            Search: data,
                          },
                        ],
                      }),
                    }),
                  );
                  // Json: "{"Code":[{"Search":"EME"}]}"
                  dispatch(
                    getCodeDescription({
                      json: JSON.stringify({
                        Code: [
                          {
                            CodeId: data,
                          },
                        ],
                      }),
                    }),
                  );
                }}
              />
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-8">
            <div style={{ background: '#F8F8F8', height: '100px' }} className="p-2 mb-4">
              <p style={{ color: '#959595', fontSize: '14px', marginBottom: '0px' }}>
                Code Description
              </p>
              <p>{codeDetails}</p>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <Field
                name={`modifier1`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'Modifier1'}
                disabled={disable}
                options={codeModifier}
                placeholder="Modifier 1"
                onChange={value => {
                  handleCodeChange(value, 'codeModifier1');
                }}
              />
              {/* <label class="placeholder-label">Modifier 1</label> */}
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-8">
            <div style={{ background: '#F8F8F8', height: '100px' }} className="p-2 mb-4">
              <p style={{ color: '#959595', fontSize: '14px', marginBottom: '0px' }}>
                Modifier 1 Description
              </p>
              <p>{codeModifierId1}</p>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <Field
                name={`modifier2`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'modifier2'}
                disabled={disable}
                options={codeModifier}
                placeholder="Modifier 2"
                onChange={value => {
                  handleCodeChange(value, 'codeModifier2');
                }}
              />
              {/* <label class="placeholder-label">Modifier 2</label> */}
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-8">
            <div style={{ background: '#F8F8F8', height: '100px' }} className="p-2 mb-4">
              <p style={{ color: '#959595', fontSize: '14px', marginBottom: '0px' }}>
                Modifier 2 Description
              </p>
              <p>{codeModifierId2}</p>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <Field
                name={`modifier3`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'modifier3'}
                disabled={disable}
                options={codeModifier}
                placeholder="Modifier 3"
                onChange={value => {
                  handleCodeChange(value, 'codeModifier3');
                }}
              />
              {/* <label class="placeholder-label">Modifier 3</label> */}
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-8">
            <div style={{ background: '#F8F8F8', height: '100px' }} className="p-2 mb-4">
              <p style={{ color: '#959595', fontSize: '14px', marginBottom: '0px' }}>
                Modifier 3 Description
              </p>
              <p>{codeModifierId3}</p>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="UnitOfMeasurementId"
                type="text"
                component={ReduxSelect}
                validate={validate.required}
                fieldName={'Unit of Measurement'}
                placeholder={'Unit of Measure*'}
                options={UnitOfMeasurement}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`IsPack`}
                type="text"
                component={ReduxCheckbox}
                fieldName={'Bundled service code'}
                label={'This is a bundled service code '}
                // disabled={disable}
              />
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12 mt-5">
            <div class="form-group">
              <Field
                name="ShortServiceDescription"
                type="text"
                component={ReduxTextarea}
                validate={validate.required}
                fieldName={'Short Description'}
                // placeholder={'Short Description*'}
                disabled={disable}
              />
              <label class="placeholder-label" style={{ paddingLeft: '5px' }}>
                Short Description*
              </label>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group">
              <Field
                name={`detailDescription`}
                type="textarea"
                component={ReduxTextarea}
                // validate={validate.required}
                fieldName={'detailDescription'}
                disabled={disable}
                style={{
                  background: '#f8f8f8',
                  paddingLeft: '5px',
                  height: '100px',
                  minHeight: '100px',
                }}
              />
              <label class="placeholder-label" style={{ paddingLeft: '5px' }}>
                Detailed Description
              </label>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-12 col-lg-12 text-center">
            {/* <input type="submit" class="btn px-5" value={ServiceCodeId ? ` UPDATE` : `ADD`} /> */}
            <SubmitButton
              className="px-5"
              value={ServiceCodeId ? ` UPDATE` : `ADD`}
              style={{height: '45px'}}
              loading={isUpsertFetching}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
const mapStateToProps = state => {
  const { combos, get_service_code } = state;
  const { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode } = get_service_code;
  let UnitOfMeasurement = [];
  if (combos.data && combos.data['UnitOfMeasurement']) {
    UnitOfMeasurement = combos.data['UnitOfMeasurement'] || [];
  }
  return {
    UnitOfMeasurement,
    isFetchingGetServiceCode,
    // WorkSkill: combos.data.WorkSkill,
    // isEmployedValue: selector(state, 'isEmployed'),
  };
};
const ReduxServiceCodeForm = reduxForm({
  form: 'serviceCodeForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(ServiceCodeForm)));

const mapStateToPropsParent = ({
  upsert_service_code,
  list_service_code,
  delete_service_code,
  get_service_code,
  combo_code,
  get_code_description,
}) => {
  const { data: serviceCodeListData, isFetching } = list_service_code;
  const { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode } = get_service_code;
  const { data: comboCode } = combo_code;
  const { data: codeDesc } = get_code_description;
  const { isFetching: isUpsertFetching } = upsert_service_code;

  let codeDetails;
  let codeId;
  let codeModifierValue;
  let codeModifierIndex;
  let code;
  let codeModifier;
  let isInserted = false;
  let isRemoved = false;
  let serviceCodeList = [];
  let serviceCodeinfo = {};
  if (upsert_service_code && upsert_service_code.data && upsert_service_code.data.success) {
    isInserted = true;
  }
  if (delete_service_code && delete_service_code.data && delete_service_code.data.success) {
    isRemoved = true;
  }
  if (serviceCodeListData && serviceCodeListData.ServiceCode) {
    serviceCodeList = serviceCodeListData.ServiceCode || [];
  }
  if (serviceCodeinfoData && serviceCodeinfoData.ServiceCode) {
    serviceCodeinfo = serviceCodeinfoData.ServiceCode[0];
  }

  if (comboCode && comboCode[0].Code) {
    code = comboCode[0].Code;
  }
  if (comboCode && comboCode[0].CodeModifier) {
    codeModifier = comboCode[0].CodeModifier;
  }
  if (codeDesc && codeDesc) {
    codeDetails = codeDesc[0].Code && codeDesc[0].Code[0] && codeDesc[0].Code[0].CodeDescription;
    codeId = codeDesc[0].Code && codeDesc[0].Code[0] && codeDesc[0].Code[0].CodeId;
    codeModifierValue =
      codeDesc[0].CodeModifier &&
      codeDesc[0].CodeModifier[0] &&
      codeDesc[0].CodeModifier[0].CodeModifierDescription;
    codeModifierIndex =
      codeDesc[0].CodeModifier &&
      codeDesc[0].CodeModifier[0] &&
      codeDesc[0].CodeModifier[0].CodeModifierIndex;
  }
  return {
    isInserted,
    isRemoved,
    serviceCodeList: serviceCodeList,
    isFetching: isFetching,
    serviceCodeinfo: serviceCodeinfo,
    code,
    codeModifier,
    codeDetails,
    codeId,
    codeModifierValue,
    codeModifierIndex,
    isFetchingGetServiceCode,
    isUpsertFetching,
  };
};
export default connect(mapStateToPropsParent)(ServiceCode);
const selector = formValueSelector('serviceCodeForm');
