import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CollapseWithCheckbox from '../../../../../customScreens/collapseWithCheckbox';
import {
  externalProviderFilterGet,
  externalProviderListGet,
} from '../../../../../../store/actions';
import Loader, { Loader2 } from '../../../../../../Components/Loader';

class ExternalCareTeamListFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersArray: null,
      filters: {},
    };
  }
  getFilterData = () => {
    const { Country, Specialty, Gender, ZipCode, City, State } = this.state.filters;
    let data = {
      ExternalProvider: [
        {
          IsExternalStaff: this.props.IsInvited ? true : null,
          CountryISO: 'USA',
          // CountryISO: Country ? Country.join() : 'USA',
          // State: State && State.join(),
          // City: City && City.join(),
          // ZipCode: ZipCode && ZipCode.join(),
          // Specialty: Specialty && Specialty.join(),
          // Gender: Gender && Gender.join(),
        },
      ],
    };
    this.props.dispatch(externalProviderFilterGet(data));
  };
  componentDidMount() {
    this.getFilterData();
  }
  componentWillReceiveProps({ externalProviderFilter }) {
    if (
      externalProviderFilter &&
      JSON.stringify(externalProviderFilter) != JSON.stringify(this.props.externalProviderFilter)
    ) {
      this.setState({
        filtersArray: Object.keys(externalProviderFilter),
        externalProviderFilter: externalProviderFilter,
      });
    }
  }
  handleCheck = (checkedArray, filterName) => {
    let filters = {
      ...this.state.filters,
      [filterName]: checkedArray,
    };
    this.setState({ filters });
  };
  getArrayToString = list => {
    return list && list.length > 0 ? list.join() : null;
  };
  filterApply = () => {
    // this.getFilterData();
    const { Country, Specialty, Gender, ZipCode, City, State } = this.state.filters;
    let filtersData = {
      // Search: '',
      Specialty: this.getArrayToString(Specialty),
      // CountryISO: Country ? getArrayToString(Country) : 'USA',
      State: this.getArrayToString(State),
      City: this.getArrayToString(City),
      ZipCode: this.getArrayToString(ZipCode),
      Gender: this.getArrayToString(Gender),
      IsInvited: this.props.IsInvited && 1,
    };
    let data = {
      Json: JSON.stringify({
        ExternalProvider: [filtersData],
      }),
    };
    this.props.dispatch(externalProviderListGet(data));
    this.props.getFilterInfo(filtersData);

    // "Json": "{\"ExternalProvider\":[{\"Search\":\"ARDALAN\",\"Specialty\":\"HOSPITALIST,INTERNAL MEDICINE\",\"Gender\":\"F,M\",\"CountryISO\":null,\"State\":\"VA,MD,CA,SC,FL,GA\",\"City\":\"ATLANTA,LAWRENCEVILLE,ARLINGTON\",\"ZipCode\":\"222053610\"}]}"
  };
  resetFilterApply = () => {
    this.props.getFilterInfo({});
    this.setState({
      filters: {},
    });
  };
  render() {
    const { filtersArray, externalProviderFilter } = this.state;
    const { isFetching } = this.props;
    return (
      <div className="card border-0">
        <div className="row card-body">
          <div className="col-12">
            <p className="mb-1 mt-1" style={{ color: '#959595', fontSize: '16px' }}>
              Filter by
            </p>
            <Loader2 loading={isFetching} />
            {filtersArray &&
              filtersArray.map((item, index) => {
                let FilterData = JSON.parse(externalProviderFilter[item]);
                if (FilterData && FilterData.length > 0) {
                  return (
                    <CollapseWithCheckbox
                      filterData={FilterData}
                      name={item}
                      handleCheck={this.handleCheck}
                      collapsekey={index}
                    />
                  );
                }
              })}
          </div>
          <div className="col-12 text-center">
            <input
              type="button"
              className="btn px-4 mr-2"
              value={'Reset'}
              onClick={this.resetFilterApply}
            />
            <input type="button" className="btn px-4" value={'Apply'} onClick={this.filterApply} />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ external_provider_filter_get }) => {
  const { data: externalCareTeamData, isFetching } = external_provider_filter_get;

  let externalProviderFilter = [];

  if (externalCareTeamData && externalCareTeamData.Filter[0]) {
    externalProviderFilter = externalCareTeamData.Filter[0];
  }

  return {
    externalProviderFilter,
    isFetching,
  };
};
export default connect(mapStateToProps)(withRouter(ExternalCareTeamListFilter));
