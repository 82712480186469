import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { PopoverMenu } from '../../Components/PopoverMenu';
import AntdModal from '../../Components/CustomModal';
import { studentQueueListGetEnt, studentQueueDelete } from '../../store/actions';

class MultiActionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { RowRecord, getPopOverData } = this.props;
    return (
      <PopoverMenu
        OptionData={getPopOverData ? getPopOverData(RowRecord) : null}
        placement="left"
      />
    );
  }
}

export default connect()(withRouter(MultiActionMenu));
