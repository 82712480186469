import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QuestionType from '../QuestionConfiguration/questionComponent';
import Loader, { Loader2 } from '../../../Components/Loader';

class ConfigutationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      SurveyQuestion,
      SurveyId,
      onNext,
      setValue,
      scope,
      goToPreviuos,
      handleNext,
      isMandatory,
      name,
      TabName,
      SurveyItem,
      Sequence,
      onSort,
      SurveyQuestionList,
      activeKey,
      onMoveTo,
      onEditAction,
      Draggable,
      UnDraggable,
      onQuestionStateChange,
      questionsListOptions,
      isAdmin,
      setToggle,
    } = this.props;
    // const { SurveyId, SurveyMainTitle, SurveyQuestion } = SurveyQuestionList || {};
    return (
      <div
        className="row survery-question-box client-screening"
        style={{ maxWidth: 'unset', marginTop: '0px' }}
      >
        {/* <h5>{TabName}</h5> */}
        {/* {isMandatory && <MandatoryWarning />} */}

        <div className="col-12 mt-2">
          {SurveyQuestion ? (
            <div
              style={{
                padding: '5px',
                margin: '0px 5px',
              }}
            >
              <QuestionType
                SurveyQuestion={SurveyQuestion}
                SurveyId={SurveyId}
                Sequence={Sequence}
                TabName={TabName}
                onNext={onNext}
                setValue={setValue}
                setToggle={setToggle}
                scope={scope}
                level={0}
                onSort={onSort}
                SurveyQuestionList={SurveyQuestionList}
                activeKey={activeKey}
                onMoveTo={onMoveTo}
                onEditAction={onEditAction}
                UnDraggable={UnDraggable}
                Draggable={Draggable}
                onQuestionStateChange={onQuestionStateChange}
                questionsListOptions={questionsListOptions}
                isAdmin={isAdmin}
              />
            </div>
          ) : (
            <div className="mt-5 text-center">
              <h6 className="blank-question-info">
                Customize this category by adding or moving questions
              </h6>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(ConfigutationTab);
