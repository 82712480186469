import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import validate, {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from '../../validation/validate';
import { listCombos, getClientCounselor, getDynamicComos } from '../../../../store/actions';

const EmergencyContact = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  StateProvince,
  Country,
  change,
  disable,
  isEmergencyContactValue,
  dispatch,
}) => {
  if (isEmergencyContactValue) {
  }

  const countryChange = (countryISO, currentIndex) => {
    if (countryISO) {
      dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
  };
  return (
    <>
      {fields.map((EmergencyContact, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Emergency Contact {index + 1}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete Contact
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.firstName`}
                    type="text"
                    // validate={validate.required}
                    component={ReduxInput}
                    fieldName={'First Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">First Name*</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.lastName`}
                    type="text"
                    // validate={validate.required}
                    component={ReduxInput}
                    fieldName={'Last Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Last Name*</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.line1`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Address Line 1</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.line2`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Address Line 2</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.city`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              {/* <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.country`}
                    type="text"
                    errorMessage="Country Required"
                    component={ReduxSelect}
                    options={Country}
                    // validate={Validator.required}
                    onChange={countryISO => countryChange(countryISO, index)}
                    placeholder="Country"
                    fieldName={'Country'}
                    disabled={disable}
                  />
                </div>
              </div> */}
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.stateOrProvince`}
                    type="text"
                    component={ReduxSelect}
                    options={StateProvince}
                    placeholder="State/Province/Territories"
                    fieldName="State/Province/Territories"
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.zip`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Postal/Zip Code</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.relationshipId`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Relationship*"
                    options={[
                      {
                        Text: 'optionsPending',
                        Value: 'optionsPending',
                      },
                    ]}
                    fieldName={'Relationship'}
                    disabled={disable}
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4 d-flex">
                <div class="form-group w-100">
                  <Field
                    name={`${EmergencyContact}.secondaryCN`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${EmergencyContact}.email`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">E-mail</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}>
            {fields.length >= 1 && <label> + Add Another Contact</label>}
            {fields.length == 0 && <label> + Add Contact</label>}
          </a>
        </div>
      </div>
    </>
  );
};
const EmergencyContact1 = connect(state => {
  const isEmergencyContactValue = selector(state, 'isEmergencyContact');
  return {
    isEmergencyContactValue,
  };
})(EmergencyContact);

const Demographic = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    NamePrefix,
    NameSuffix,
    HomeAddressType,
    Counsellor,
    Center,
    ReferenceSource,
    style,
    StateProvince,
    disable,
    Country,
    dispatch,
    studentCenter,
    studentId,
    Counsellors,
  } = props;
  const countryChange = (countryISO, currentIndex) => {
    if (countryISO) {
      dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
  };
  const centerChange = (filterCenterId, currentIndex) => {
    if (filterCenterId) {
      dispatch(
        getClientCounselor({
          CenterId: filterCenterId,
        }),
      );
    }
    let comboTypes = {
      ComboTypes: 'CenterServiceTypeCenter',
      Json: JSON.stringify({
        Combo: [
          {
            StudentId: studentId,
            StudentCenterId: filterCenterId,
          },
        ],
      }),
    };
    dispatch(getDynamicComos(comboTypes));
  };
  return (
    <form onSubmit={handleSubmit(handleNext)} style={style}>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`providerId`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Provider ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Provider ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`providerName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Provider Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Provider Name</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`${EmergencyContact}.line1`}
              type="text"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 1</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`${EmergencyContact}.line2`}
              type="text"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 2</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`Postal/Zip Code`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'PostalZipCode'}
              disabled={disable}
            />
            <label class="placeholder-label">Postal/Zip Code</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`phone`}
              type="text"
              component={ReduxSelect}
              options={StateProvince}
              placeholder="Phone"
              fieldName="Phone"
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`Tax ID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'TaxId'}
              disabled={disable}
            />
            <label class="placeholder-label">Tax ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`StateLicenseInformation`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'State License Information'}
              disabled={disable}
            />
            <label class="placeholder-label">State License Information</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`GroupNumber`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Group Number'}
              disabled={disable}
            />
            <label class="placeholder-label">Group Number</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`npi`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'npi'}
              disabled={disable}
            />
            <label class="placeholder-label">NPI</label>
          </div>
        </div>
        {/* <div class="col-12">
          <FieldArray
            name="EmergencyContact"
            component={EmergencyContact1}
            // RelationShip={RelationShip}
            StateProvince={StateProvince}
            Country={Country}
            // change={change}
            disable={disable}
            dispatch={props.dispatch}
          />
        </div> */}
      </div>
      <div class="row pagination-button">
        <div class="col-12 text-right">
          <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
            Next
          </button>
        </div>
      </div>
    </form>
  );
};
export default connect((state, props) => {
  const { get_client_counselor } = state;
  let { data, isFetching, error } = get_client_counselor;
  let Counsellors = [];
  let studentCenter = selector(state, 'center');
  let studentId = props.studentId || -1;

  if (data && data.data) {
    let CounsellorData = JSON.parse(data.data);
    Counsellors =
      CounsellorData &&
      CounsellorData.map(item => {
        return {
          Value: item.StaffId,
          Text: item.StaffName,
        };
      });
    Counsellors.unshift({ Value: null, Text: 'Please Select' });
  }
  return {
    studentCenter,
    studentId,
    Counsellors: Counsellors,
  };
})(
  reduxForm({
    form: 'patientEnrollmentForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Demographic),
);

const selector = formValueSelector('patientEnrollmentForm');
