import React, { useState } from 'react';
import { Field, reduxForm, formValueSelector, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';
import validate, {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from '../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxDateInput,
  ReduxTextarea,
  ReduxPhoneInput,
  ReduxAutoComplete,
} from '../../../component/custom-redux-components';
import { getPhoneNumber, getCountryCalling } from '../../../util/phoneFormatter';
import {
  listCombos,
  getClientCounselor,
  getDynamicComos,
  validateSSN,
} from '../../../store/actions';
import WarningComponent from '../../../core/warning-component';


const Validator = {
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var exitDate = values.exitDate;
    var date1 = new Date(exitDate).getTime();
    var date2 = new Date(value).getTime();

    if (date1 > date2) {
    } else {
      return `shouldn't be greater than Discharge Date`;
    }
  },
};

const StudentInformation = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    NamePrefix,
    NameSuffix,
    HomeAddressType,
    Counsellor,
    Center,
    ReferenceSource,
    style,
    StateProvince,
    disable,
    Country,
    dispatch,
    studentCenter,
    studentId,
    Counsellors,
    Filter_State,
    ClientType,
    isDirty,
    submitSucceeded,
    Phase,
    isInValidSSN,
    Msg,
    onLastSubmit,
    goToPreviuos,
    currentTab,
    tabsCount,
    change,
    handleSubmitAndNext,
    SSNNotMandatory,
    whoIsCalling,
    centerWhoIsCalling,
    orgType,
    orgName,
    relationToClient,
    exitReasons,
    isAllowToEditClosed,
  } = props;
  // const [center, setCenter] = useState(null);
  const countryChange = (countryISO, currentIndex) => {
    if (countryISO) {
      dispatch(
        listCombos({
          comboTypes: 'StateProvince,ClientType',
          CountryISO: countryISO,
        }),
      );
    }
  };
  const centerChange = (filterCenterId, currentIndex) => {
    // I am commenting this code because it is triggering warning message
    if (studentCenter !== filterCenterId) {
      change('counselorStaffId', null);
    }
    if (filterCenterId) {
      dispatch(
        getClientCounselor({
          CenterId: filterCenterId,
        }),
      );
    }
    let comboTypes = {
      ComboTypes: 'CenterServiceTypeCenter',
      Json: JSON.stringify({
        Combo: [
          {
            StudentId: studentId,
            StudentCenterId: filterCenterId,
          },
        ],
      }),
    };
    dispatch(getDynamicComos(comboTypes));
  };
  if (Counsellors.length === 0 && studentCenter) {
    centerChange(studentCenter);
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(data => {
          handleSubmitAndNext(data, currentTab, tabsCount);
        })}
        style={style}
      >
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`namePrefix`}
                type="text"
                component={ReduxSelect}
                placeholder="Prefix"
                options={NamePrefix}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`firstName`}
                type="text"
                component={ReduxInput}
                validate={validate.required}
                fieldName={'First Name'}
                disabled={disable}
              />
              <label class="placeholder-label">First Name*</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field name={`middleName`} type="text" component={ReduxInput} disabled={disable} />
              <label class="placeholder-label">Middle Name</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`lastName`}
                type="text"
                component={ReduxInput}
                validate={validate.required}
                fieldName={'Last Name'}
                disabled={disable}
              />
              <label class="placeholder-label">Last Name*</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`nameSuffix`}
                type="text"
                placeholder={'Suffix'}
                component={ReduxSelect}
                options={NameSuffix}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`homeAddressType`}
                type="text"
                placeholder={'Home Address Type'}
                component={ReduxSelect}
                options={HomeAddressType}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field name={`line1`} type="text" component={ReduxInput} disabled={disable} />
              <label class="placeholder-label">Permanent Address Line 1(Last Known Address)</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field name={`line2`} type="text" component={ReduxInput} disabled={disable} />
              <label class="placeholder-label">Permanent Address Line 2</label>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="country"
                type="text"
                errorMessage="Country Required"
                component={ReduxSelect}
                options={Country}
                // validate={Validator.required}
                onChange={countryISO => countryChange(countryISO)}
                placeholder="Country"
                fieldName={'Country'}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field name={`city`} type="text" component={ReduxInput} disabled={disable} />
              <label class="placeholder-label">City</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`stateOrProvince`}
                type="text"
                component={ReduxSelect}
                options={Filter_State}
                // options={Filter_StateProvince[countryValue]}
                placeholder="State/Province/Territories"
                fieldName="State/Province/Territories"
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field name={`zip`} type="text" component={ReduxInput} disabled={disable} />
              <label class="placeholder-label">Postal/Zip Code</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                component={ReduxDateInput}
                name="enrollDate"
                label="Admission Date*"
                validate={disable && isAllowToEditClosed ? Validator.endDate : validate.required}
                fieldName={'Admission Date'}
                disabled={isAllowToEditClosed ? !isAllowToEditClosed : disable}
                maxDate={disable && isAllowToEditClosed && new Date()}
              />
              <i class="far fa-calendar-alt form-control-icon" />
            </div>
          </div>
          {disable && (
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  component={ReduxDateInput}
                  name="exitDate"
                  label="Discharge Date"
                  fieldName={'Discharge Date'}
                  disabled={isAllowToEditClosed ? !isAllowToEditClosed : disable}
                  maxDate={new Date()}
                />
                <i class="far fa-calendar-alt form-control-icon" />
              </div>
            </div>
          )}
          {disable && (
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name="exitReason"
                  component={ReduxSelect}
                  placeholder="Discharge Reason"
                  options={exitReasons}
                  disabled={isAllowToEditClosed ? !isAllowToEditClosed : disable}
                />
              </div>
            </div>
          )}
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="center"
                component={ReduxSelect}
                placeholder="Center"
                onChange={filterCenterId => centerChange(filterCenterId)}
                options={Center}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="counselorStaffId"
                component={ReduxSelect}
                errorMessage="Field Required"
                options={Counsellors}
                placeholder="Counselor's Name*"
                validate={validate.required}
                fieldName={"Counselor's Name"}
                disabled={disable}
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="referenceSourceId"
                component={ReduxSelect}
                validate={validate.required}
                placeholder="Referral Source*"
                options={ReferenceSource}
                fieldName={'Reference Source'}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`ssn`}
                type="text"
                component={ReduxInput}
                validate={[
                  !SSNNotMandatory ? validate.ssn : validate.notRequired,
                  value => (isInValidSSN ? Msg : undefined),
                ]}
                // validate={[validate.ssn, value => (isInValidSSN ? Msg : undefined)]}
                format={ssnFormatter}
                normalize={ssnNormalize}
                disabled={disable}
                fieldName={true}
                onBlur={(event, newValue, previousValue, name) => {
                  let ssn = event.target.value;
                  ssn &&
                    ssn.length === 11 &&
                    dispatch(
                      validateSSN({
                        json: JSON.stringify({
                          Client: [
                            {
                              ClientId: studentId > 0 ? studentId : null,
                              SSN: ssn,
                              IsClinical: false,
                            },
                          ],
                        }),
                      }),
                    );
                }}
                // warn={[value => (isInValidSSN ? undefined : 'SSN already exist')]}
              />
              <label class="placeholder-label">National ID (SSN){!SSNNotMandatory && '*'}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                component={ReduxInput}
                name="dateofBirth"
                normalize={dateofbirthNormalize}
                validate={[validate.required, validate.dobStudent]}
                placeholder="MM/DD/YYYY"
                fieldName={'Date of Birth'}
                disabled={disable}
              />
              <label class="placeholder-label">Date of Birth*</label>
              <i class="far fa-calendar-alt form-control-icon" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 d-flex">
            {/* <div class="form-group pr-1 phone-code">
            <select class="custom-select enrollment-select ">
              <option selected="">+ 1</option>
              <option selected="">+ 91</option>
              <option value="">+ 44</option>
              <option value="">+ 33 </option>
            </select>
          </div> */}
            <div class="form-group w-100">
              <Field
                name={`primaryCN`}
                type="text"
                component={ReduxPhoneInput}
                // validate={validate.NumericMobile}
                // format={MobileFormatter}
                // normalize={MobileNormalize}
                fieldName={'Phone'}
                disabled={disable}
              />
              <label class="placeholder-label" style={{ top: '-5px', fontSize: '14px' }}>
                Phone
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field name={`email`} type="text" component={ReduxInput} disabled={disable} />
              <label class="placeholder-label">E-mail</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="clientTypeId"
                component={ReduxSelect}
                errorMessage="Field Required"
                options={ClientType}
                placeholder="Client Type*"
                validate={validate.required}
                fieldName={'Client Type'}
                disabled={disable}
              />
            </div>
          </div>
          {/* <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="phaseId"
                component={ReduxSelect}
                errorMessage="Field Required"
                options={Phase}
                placeholder="Student Phase/Level in the program"
                // validate={validate.required}
                fieldName={'Student Phase/Level in the program'}
                disabled={disable}
              />
            </div>
          </div> */}

          <div class="col-12 col-md-6 col-lg-8">
            <div class="form-group">
              <Field
                name={`preIntakeNotes`}
                type="textarea"
                rows="4"
                class="form-control textarea-box"
                component={ReduxTextarea}
                style={{
                  minHeight: '100px',
                }}
                disabled={disable}
              />
              <label class="placeholder-label">Notes</label>
            </div>
          </div>
        </div>

        {/* concerned person */}
        {centerWhoIsCalling && whoIsCalling == 'ConcernedPerson' && (
          <div className="row">
            <h5 className="col-12">Concerned Person Details</h5>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`relationToClient`}
                  type="text"
                  component={ReduxSelect}
                  validate={validate.required}
                  placeholder="Relation to Client*"
                  options={relationToClient}
                  fieldName={'Relation to Client'}
                  disabled={disable}
                />
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactFirstName`}
                  type="text"
                  component={ReduxInput}
                  validate={validate.required}
                  fieldName={'First Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">First Name*</label>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactLastName`}
                  type="text"
                  component={ReduxInput}
                  validate={validate.required}
                  fieldName={'Last Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">Last Name*</label>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactPrimaryPhone`}
                  type="text"
                  component={ReduxInput}
                  // validate={validate.required}
                  format={MobileFormatter}
                  normalize={MobileNormalize}
                  fieldName={'Mobile'}
                  disabled={disable}
                />
                <label class="placeholder-label">Mobile</label>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactEmail`}
                  type="text"
                  component={ReduxInput}
                  disabled={disable}
                />
                <label class="placeholder-label">E-mail</label>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactAddress`}
                  type="text"
                  component={ReduxInput}
                  disabled={disable}
                />
                <label class="placeholder-label">Permanent Address Line(Last Known Address)</label>
              </div>
            </div>
          </div>
        )}

        {/* partner organization */}
        {centerWhoIsCalling && whoIsCalling == 'PartnerOrganization' && (
          <div className="row">
            <h5 className="col-12">Partner Organization Details</h5>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`organizationType`}
                  type="text"
                  component={ReduxSelect}
                  validate={validate.required}
                  placeholder="Organization Type*"
                  options={orgType}
                  fieldName={'Organization Type'}
                  disabled={disable}
                />
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                {/* <Field
                name={`contactFirstName`}
                type="text"
                component={ReduxAutoComplete}
                validate={validate.required}
                fieldName={'Organization Name'}
                disabled={disable}
              /> */}

                <Field
                  name={`organizationName`}
                  type="text"
                  component={ReduxAutoComplete}
                  options={orgName}
                  label={'Organization Name*'}
                  disabled={disable}
                />
                {/* <label class="placeholder-label">Organization Name*</label> */}
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactPrimaryPhone`}
                  type="text"
                  component={ReduxInput}
                  // validate={validate.required}
                  format={MobileFormatter}
                  normalize={MobileNormalize}
                  fieldName={'Mobile'}
                  disabled={disable}
                />
                <label class="placeholder-label">Mobile</label>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactEmail`}
                  type="text"
                  component={ReduxInput}
                  disabled={disable}
                />
                <label class="placeholder-label">E-mail</label>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`contactAddress`}
                  type="text"
                  component={ReduxInput}
                  disabled={disable}
                />
                <label class="placeholder-label">Permanent Address Line(Last Known Address)</label>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`callerName`}
                  type="text"
                  component={ReduxInput}
                  validate={validate.required}
                  // format={MobileFormatter}
                  // normalize={MobileNormalize}
                  fieldName={'Caller Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">Caller Name*</label>
              </div>
            </div>
          </div>
        )}

        <div class="row pagination-button">
          <div class="col-12 text-right">
            {currentTab > 0 && (
              <button
                onClick={goToPreviuos}
                type="button"
                class="btn btn-outline-primary btn-eForm-Prev mr-2"
                disabled={disable}
              >
                Previous
              </button>
            )}
            {currentTab + 1 != tabsCount && (
              <button
                type="submit"
                // onClick={handleNext}
                class="btn btn-eForm-Next"
                disabled={disable}
              >
                Next
              </button>
            )}
            {currentTab + 1 == tabsCount && (
              <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
      <WarningComponent dirtyFlag={isDirty} submitSucceeded={submitSucceeded} />
    </>
  );
};
export default connect((state, props) => {
  const { get_client_counselor, combos, validate_SSN, user_feature_flag_get } = state;
  let { data, isFetching, error } = get_client_counselor;
  const { data: fFData } = user_feature_flag_get;

  let Counsellors = [];
  let Filter_State = [];
  let Phase = [];
  let isInValidSSN = false;
  let Msg = '';
  let studentCenter = selector(state, 'center');
  let countryValue = selector(state, 'country');
  let studentId = props.studentId || -1;
  let SSNNotMandatory;
  let relationToClient = [];
  let orgName = [];
  let orgType = [];
  let exitReasons = [];

  if (data && data.data) {
    let CounsellorData = JSON.parse(data.data);
    Counsellors =
      CounsellorData &&
      CounsellorData.map(item => {
        return {
          Value: item.StaffId,
          Text: item.StaffName,
        };
      });
    Counsellors.unshift({ Value: null, Text: 'Please Select' });
  }
  if (combos.data && combos.data.Filter_StateProvince) {
    Filter_State = combos.data.Filter_StateProvince[countryValue];
    Phase = combos.data.Phase;
    relationToClient = combos.data.RelationShip;
    orgName = combos.data.StudentPartnerOrganizationName;
    orgType = combos.data.ClientOrganizatonType;
    exitReasons = combos.data.ExitReason;
  }
  if (validate_SSN.data && validate_SSN.data.success) {
    isInValidSSN = validate_SSN.data.result.Result;
    Msg = validate_SSN.data.result.Msg;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    SSNNotMandatory = fFData.FeatureFlag[0].SSN_NotMandatory;
  }
  return {
    studentCenter,
    studentId,
    Counsellors: Counsellors,
    Filter_State,
    Phase: Phase,
    isDirty: isDirty('wizard')(state),
    submitSucceeded: get(state, 'form.wizard.submitSucceeded', false),
    isInValidSSN,
    Msg,
    SSNNotMandatory,
    orgType,
    orgName,
    relationToClient,
    exitReasons,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(StudentInformation),
);

const selector = formValueSelector('wizard');
