import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Drawer } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import ClientsTransactionsDrawer from './ClientsTransactionsDrawer';
import CounselorsTransactionsDrawer from './CounselorsTransactionsDrawer';

class CounselorsTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  showDrawer = ({ StaffId }) => {
    this.setState({ open: true, StaffId: StaffId });
  };

  onClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { listData } = this.props;
    const columns = [
      {
        title: 'Counselor',
        dataIndex: 'UsedBy',
        key: 'UsedBy',
        sorter: (a, b) => isComparer(a.UsedBy, b.UsedBy),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span className="d-flex align-items-center">
            <img
              style={{ height: '30px', width: '30px', borderRadius: '50%', objectFit: 'cover' }}
              src={record.StaffProfileImage || '/images/blank-profile.png'}
            />
            <span className="ml-1">{text}</span>
            <img
              src="images/icons/open_external.svg"
              className="mx-2"
              onClick={() => {
                this.showDrawer({
                  StaffId: record.StaffId,
                });
              }}
            />
          </span>
        ),
      },
      {
        title: 'Total Debited in $',
        dataIndex: 'TotalDebitedin$',
        key: 'TotalDebitedin$',
        sorter: (a, b) => isComparer(a.TotalDebitedin$, b.TotalDebitedin$),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Total Debited in Tokens',
        dataIndex: 'TotalDebitedinTokens',
        key: 'TotalDebitedinTokens',
        sorter: (a, b) => isNumericComparer(a.TotalDebitedinTokens, b.TotalDebitedinTokens),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          text && (
            <span>
              <img src="/images/smallCoin.svg" />
              <span style={{ marginLeft: '5px' }}>{text}</span>
            </span>
          ),
      },
    ];
    return (
      <div class="row">
        <div class="col-12 mb-5">
          <div class="card border-0 ">
            <div class="row card-body">
              <div class="col-12">
                {/* <Loader2 loading={isFetching} /> */}
                <div className="">
                  <Table
                    columns={columns}
                    dataSource={listData}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div class="dataTables_wrapper no-footer">
                            <div class="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a class="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a class="paginate_button next">Next</a>
                              ) : (
                                <a class="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      // hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.open && (
          <Drawer
            className="custom-drawer"
            title="Token Transactions"
            placement="right"
            onClose={this.onClose}
            open={this.state.open}
            width="700px"
            bodyStyle={{ backgroundColor: '#fff' }}
            visible={this.state.open}
            destroyOnClose={true}
          >
            <CounselorsTransactionsDrawer StaffId={this.state.StaffId} />
          </Drawer>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ tokens_counselor_transaction_history }) => {
  const { data: counselorTokenData, isFetching, error } = tokens_counselor_transaction_history;
  let listData = [];
  if (counselorTokenData && counselorTokenData.TokenManagement) {
    listData = counselorTokenData.TokenManagement;
  }

  return {
    isFetching,
    listData,
  };
};
export default connect(mapStateToProps)(CounselorsTransactions);
