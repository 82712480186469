import React, { Component } from 'react';
import { Modal, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../Components/Loader';
import { achValidate } from '../store/actions';
import renderHTML from 'react-render-html';

class ConfirmACHPayment extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
    };
  }
  handleChange = e => {
    let target = e.target;
    // let value = e.target.value;
    // let pat = /^[0-9]{0,2}$/;
    // if (value.match(pat)) {
    //   value = parseInt(value);
    //   this.setState({
    //     ...this.state,
    //     [target.name]: value || '',
    //   });
    // }
    this.setState({
      ...this.state,
      [target.name]: target.value,
      isAmt1Req: false,
      isAmt2Req: false,
    });
  };
  handleVerify = () => {
    const { amt1, amt2 } = this.state;
    let data = {
      amt1: amt1,
      amt2: amt2,
    };

    var BankInfo = {
      Json: JSON.stringify(data),
    };
    if (!amt1 || !amt2) {
      if (!amt1) {
        this.setState({
          isAmt1Req: true,
        });
      } else if (!amt2) {
        this.setState({
          isAmt2Req: true,
        });
      }
    } else {
      this.props.dispatch(achValidate(BankInfo));
    }
  };
  componentWillReceiveProps({ success }) {
    if (success && success != this.props.success) {
      this.setState({
        success: success,
      });
    }
  }
  render() {
    const {
      onOk,
      onCancel,
      handleSubmit,
      onModalCancel,
      info,
      isFetching,
      result,
      success,
    } = this.props;
    const { isAmt1Req, isAmt2Req, amt1, amt2 } = this.state;
    return (
      <div class="modal-content border-0 mt-5">
        <div class="modal-header justify-content-center flex-wrap pb-0 border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onModalCancel}>
            ×
          </button>
          {/* <h4 class="modal-title col-12">Welcome {localStorage.getItem('userName')}</h4> */}
          {/* <div className="w-100 text-center mb-3">
            <img src="images/SoberPeer_Logo.png" alt="asas" style={{ height: '70px' }} />
          </div> */}
          <h4 class="modal-title col-12">
            <h5>Microdeposit amounts in cents</h5>
          </h4>
        </div>
        <div class="modal-body col-md-12 pr-0 enrollment-form pb-2">
          <Loader2 loading={isFetching} />

          <div className="container text-center">
            {!result ? renderHTML(info) : <h6>{result}</h6>}

            {!this.state.success && (
              <div class="row justify-content-center align-items-center ">
                <div class="col-4">
                  <div class="form-group">
                    <input
                      name="amt1"
                      type="text"
                      class="form-control"
                      value={amt1}
                      placeholder="1st deposit"
                      onChange={this.handleChange}
                      maxLength="6"
                    />
                    {isAmt1Req && (
                      <span
                        className="error-text"
                        style={{ fontSize: '12px', position: 'absolute' }}
                      >
                        Please enter 1st deposit
                      </span>
                    )}
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <input
                      name="amt2"
                      type="text"
                      class="form-control"
                      value={amt2}
                      placeholder="2nd deposit"
                      onChange={this.handleChange}
                      maxLength="6"
                    />
                    {isAmt2Req && (
                      <span
                        className="error-text"
                        style={{ fontSize: '12px', position: 'absolute' }}
                      >
                        Please enter 2nd deposit
                      </span>
                    )}
                  </div>
                </div>
                <div class="col-4">
                  <button type="button" class="btn black-btn " onClick={this.handleVerify}>
                    Verify
                  </button>
                </div>
              </div>
            )}
          </div>

          <div class="justify-content-center border-top-1 text-center"></div>
        </div>
        <div class="modal-footer justify-content-center border-top-0">
          {!this.state.success ? (
            <button
              type="button"
              class="btn black-btn font-weight-bold mr-2"
              onClick={onModalCancel}
            >
              Skip
            </button>
          ) : (
            <button
              type="button"
              class="btn black-btn font-weight-bold mr-2"
              onClick={onModalCancel}
            >
              Proceed
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { ach_validation } = state;
  const { data, isFetching } = ach_validation;
  let result = '';
  let success = false;
  if (ach_validation.data) {
    result = ach_validation.data.result;
    success = ach_validation.data.success;
  }
  return {
    isFetching,
    result,
    success,
  };
};
export default connect(mapStateToProps)(withRouter(ConfirmACHPayment));
