import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Drawer } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import getParam from '../../../../util/getParam';
import AuthorizationDetailDrawer from '../../authorizations/components/authorizationDetailDrawer';
import { payerPortalAuthorizationGet } from '../../../../store/actions';
import { Loader2 } from '../../../../Components/Loader';

class ContractAuthorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authRecord: null,
      isDetailDrawerOpen: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(
      payerPortalAuthorizationGet({
        JSON: JSON.stringify({
          Authorizations: [
            {
              ClientId: this.props.clientId,
              SmartContractId: this.props.contractId,
            },
          ],
        }),
      }),
    );
  }
  getAuthDetails(authRecord) {
    this.setState({
      isDetailDrawerOpen: true,
      authRecord,
    });
  }

  componentWillReceiveProps({ isSuccess, isReqSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState({
        isDetailDrawerOpen: false,
      });
      this.props.dispatch(
        payerPortalAuthorizationGet({
          JSON: JSON.stringify({
            Authorizations: [
              {
                ClientId: this.props.clientId,
                SmartContractId: this.props.contractId,
              },
            ],
          }),
        }),
      );
    }
    // if (isReqSuccess && isReqSuccess != this.props.isReqSuccess) {
    //   this.setState({
    //     isDetailDrawerOpen: false,
    //   });
    // }
  }

  openAndCloseModal = () => {
    this.setState({ isDetailDrawerOpen: !this.state.isDetailDrawerOpen });
  };
  render() {
    const { authDetails, tabsCount, goToPreviuos, currentTab, disable, goToNext } = this.props;
    const { authRecord, isDetailDrawerOpen } = this.state;
    let columns = [
      {
        title: 'Authorization Number',
        dataIndex: 'AuthNumber',
        key: 'AuthNumber',
        sorter: (a, b) => isNumericComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Service Code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Date',
        dataIndex: 'StartDate',
        key: 'StartDate',
        sorter: (a, b) => isDateComparer(a.StartDateSort, b.StartDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date',
        dataIndex: 'EndDate',
        key: 'EndDate',
        sorter: (a, b) => isDateComparer(a.EndDateSort, b.EndDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Review Date',
        dataIndex: 'AuthReviewDate',
        key: 'AuthReviewDate',
        sorter: (a, b) => isDateComparer(a.AuthReviewDateSort, b.AuthReviewDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Visits Approved',
        dataIndex: 'VisitApproved',
        key: 'VisitApproved',
        sorter: (a, b) => isNumericComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
        align: 'right',
      },
      {
        title: 'Visits Remaining',
        dataIndex: 'VisitRemaining',
        key: 'VisitRemaining',
        sorter: (a, b) => isNumericComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
        align: 'right',
      },
      {
        title: 'Notes',
        dataIndex: 'AuthNote',
        key: 'AuthNote',
        sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'StatusText',
        key: 'StatusText',
        sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span class={`client-info-text ${record.StatusClassName}`}>{text}</span>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'x',
        key: 'x',
        render: (text, record) => (
          <div
            className="text-center"
            onClick={() => {
              this.getAuthDetails(record);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={`images/openIcon.svg`} />
          </div>
        ),
      },
    ];
    return (
      <div className="row">
        <Loader2 loading={this.props.isFetching} />
        <Table
          columns={columns}
          dataSource={authDetails}
          pagination={false}
          // className="nested-tables"
        />

        <div class="col-12 text-right mt-3">
          {(currentTab > 0 || currentTab + 1 == tabsCount) && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="button"
              onClick={goToNext}
              class="btn btn-eForm-Next"
              disabled={disable}
              style={{ padding: '10px 39px' }}
            >
              Next
            </button>
          )}
        </div>

        <Drawer
          className="custom-drawer print-drawer"
          title={'View Authorization'}
          open={isDetailDrawerOpen}
          onClose={() => {
            this.setState({
              isDetailDrawerOpen: false,
              authRecord: null,
            });
          }}
          width={900}
          bodyStyle={{ paddingBottom: 80, backgroundColor: 'white' }}
          destroyOnClose={true}
          placement="right"
          // className="print-drawer"
        >
          <AuthorizationDetailDrawer
            smartContractId={this.props.contractId}
            authDetails={authRecord}
            dispatch={this.props.dispatch}
            openAndCloseModal={this.openAndCloseModal}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({
  payer_portal_authorization_get,
  payer_portal_authorization_status_upsert,
  payer_portal_request_insert,
}) => {
  const { data } = payer_portal_authorization_status_upsert;
  let { data: requestData } = payer_portal_request_insert;
  const { data: authorizationData, isFetching } = payer_portal_authorization_get;
  let authDetails = [];

  let isSuccess = false;
  let isReqSuccess = false;
  if (data && data.success) {
    isSuccess = data.success;
  }
  if (requestData && requestData.success) {
    isReqSuccess = requestData.success;
  }

  if (
    authorizationData &&
    authorizationData.Authorizations &&
    authorizationData.Authorizations[0].SmartContract
  ) {
    authDetails =
      authorizationData.Authorizations[0].SmartContract &&
      authorizationData.Authorizations[0].SmartContract[0] &&
      authorizationData.Authorizations[0].SmartContract[0].Authorization;
  }
  return { authDetails, isSuccess, isReqSuccess, isFetching };
};

export default connect(mapStateToProps)(withRouter(ContractAuthorization));
