import React, { Component } from 'react';
import { Table, Checkbox, Radio, Tooltip, Avatar, DatePicker as AntdDatePicker } from 'antd';
import TextareaAutosize from 'react-autosize-textarea';
import Loader, { Loader2 } from '../../Components/Loader';
import _blank from '../../images/blank-profile.png';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { appSubscribersUserSearch, broadCastUserListGet } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { Modal, Button, Menu } from 'antd';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
  ReduxTextarea,
} from '../../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import MultipleCategoryCheckboxAll2 from '../../Components/multipleCategoryCheckbox2';

const { SubMenu } = Menu;
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Mobile app referral sent successfully',
  });
};

class ReduxSelectGroupStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      searchStr: '',
      selectedStudent: [],
      searchedStudent: [],
      checkedItems: [],
      studentData: [
        {
          value: '100',
          label: 'Select All',
          children: [
            // {
            //   FirstName: 'John',
            //   LastName: 'Smith',
            //   UserGuid: '8CB7D774-A5E7-4261-A17D-C58EDD91A763',
            //   DisplayName: 'JohnSmith',
            //   ProfileImgUrl:
            //     'https://drattach.blob.core.windows.net/dr-blob-container-v1/920109c2-1ec8-4207-8c35-e90f4c34fed6.jpeg',
            //   JoinedDate: '01-Aug-2019',
            //   value: '8CB7D774-A5E7-4261-A17D-C58EDD91A763',
            //   label: 'JohnSmith',
            // },
          ],
        },
      ],
    };
  }

  // onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  handelGui = () => {
    this.props.GetAllSelectedStudents(this.state.selected);
  };
  componentDidMount() {
    // this.props.dispatch(getAllVacation({}));

    if (this.props.SmallGroupGuid) {
      let data = {
        Broadcast: [
          {
            SmallGroupGuid: this.props.SmallGroupGuid,
            FilterBy: null,
          },
        ],
      };

      if (this.props.isBroacastScreen) {
        this.props.dispatch(broadCastUserListGet({ Json: JSON.stringify(data) }));
      } else {
        this.props.dispatch(appSubscribersUserSearch({ json: JSON.stringify(data) }));
      }
    } else {
      let data = { Broadcast: [{ FilterBy: null }] };
      if (this.props.isBroacastScreen) {
        this.props.dispatch(broadCastUserListGet({ Json: JSON.stringify(data) }));
      } else {
        this.props.dispatch(appSubscribersUserSearch({ json: JSON.stringify(data) }));
      }
    }
    this.input.focus();
  }

  componentWillReceiveProps({ searchedStudent }) {
    if (
      searchedStudent &&
      JSON.stringify(searchedStudent) != JSON.stringify(this.props.searchedStudent)
    ) {
      let newSearchedStudent = searchedStudent.map(item => {
        return { ...item, value: item.Value };
      });

      this.state.studentData = [
        {
          value: '100',
          label: 'Select All',
          children: newSearchedStudent,
        },
      ];
    }
  }

  getSerachedStudent = () => {
    if (this.props.SmallGroupGuid) {
      let data = {
        SearchStr: this.state.searchStr,
        json: JSON.stringify({
          Broadcast: [
            {
              SmallGroupGuid: this.props.SmallGroupGuid,
              FilterBy: null,
            },
          ],
        }),
      };
      if (this.props.isBroacastScreen) {
        const { userTypeFilter, centerFilterArray, programTypeFilter, userTagsFilter } = this.props;

        let data = {
          Json: JSON.stringify({
            Broadcast: [
              {
                SmallGroupGuid: this.props.SmallGroupGuid,
                FilterBy: userTypeFilter.length > 0 ? userTypeFilter.join(',') : null,
                CenterIds: centerFilterArray.length > 0 ? centerFilterArray.join(',') : null,
                ProgramTypeIds: programTypeFilter.length > 0 ? programTypeFilter.join(',') : null,
                TagTypeIds: userTagsFilter.length > 0 ? userTagsFilter.join(',') : null,
              },
            ],
          }),
        };
        this.props.dispatch(broadCastUserListGet(data));
      } else {
        this.props.dispatch(appSubscribersUserSearch(data));
      }
    } else {
      let data = {
        SearchStr: this.state.searchStr,
        json: JSON.stringify({ Broadcast: [{ FilterBy: null }] }),
      };
      if (this.props.isBroacastScreen) {
        const { userTypeFilter, centerFilterArray, programTypeFilter, userTagsFilter } = this.props;
        let data = {
          Json: JSON.stringify({
            Broadcast: [
              {
                SearchStr: this.state.searchStr,
                FilterBy: userTypeFilter.length > 0 ? userTypeFilter.join(',') : null,
                CenterIds: centerFilterArray.length > 0 ? centerFilterArray.join(',') : null,
                ProgramTypeIds: programTypeFilter.length > 0 ? programTypeFilter.join(',') : null,
                TagTypeIds: userTagsFilter.length > 0 ? userTagsFilter.join(',') : null,
              },
            ],
          }),
        };
        this.props.dispatch(broadCastUserListGet(data));
      } else {
        this.props.dispatch(appSubscribersUserSearch(data));
      }
    }
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.getSearchStr(value);
    if (this.props.SmallGroupGuid) {
      let data = {
        SearchStr: value,
        saveSearch: true,
        json: JSON.stringify({
          Broadcast: [
            {
              SmallGroupGuid: this.props.SmallGroupGuid,
              FilterBy: null,
            },
          ],
        }),
      };
      if (this.props.isBroacastScreen) {
        const { userTypeFilter, centerFilterArray, programTypeFilter, userTagsFilter } = this.props;

        let data = {
          Json: JSON.stringify({
            Broadcast: [
              {
                SmallGroupGuid: this.props.SmallGroupGuid,
                SearchStr: value,
                FilterBy: userTypeFilter.length > 0 ? userTypeFilter.join(',') : null,
                CenterIds: centerFilterArray.length > 0 ? centerFilterArray.join(',') : null,
                ProgramTypeIds: programTypeFilter.length > 0 ? programTypeFilter.join(',') : null,
                TagTypeIds: userTagsFilter.length > 0 ? userTagsFilter.join(',') : null,
              },
            ],
          }),
        };
        this.props.dispatch(broadCastUserListGet(data));
      } else {
        this.props.dispatch(appSubscribersUserSearch(data));
      }
    } else {
      let data = {
        SearchStr: value,
        saveSearch: true,
        json: JSON.stringify({ Broadcast: [{ FilterBy: null }] }),
      };
      // this.props.dispatch(broadCastUserListGet(data));
      if (this.props.isBroacastScreen) {
        const { userTypeFilter, centerFilterArray, programTypeFilter, userTagsFilter } = this.props;
        let data = {
          Json: JSON.stringify({
            Broadcast: [
              {
                SearchStr: value,
                FilterBy: userTypeFilter.length > 0 ? userTypeFilter.join(',') : null,
                CenterIds: centerFilterArray.length > 0 ? centerFilterArray.join(',') : null,
                ProgramTypeIds: programTypeFilter.length > 0 ? programTypeFilter.join(',') : null,
                TagTypeIds: userTagsFilter.length > 0 ? userTagsFilter.join(',') : null,
              },
            ],
          }),
        };
        this.props.dispatch(broadCastUserListGet(data));
      } else {
        this.props.dispatch(appSubscribersUserSearch(data));
      }
    }
  };
  onCheckBoxChange(e, item) {
    // current array of selected
    const selected = this.state.selected;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to selected array
      selected.push(item);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = selected.indexOf(item);
      selected.splice(index, 1);
    }
    // update the state with the new array of selected
    this.setState({ selected: selected });
    // this.props.updatedSelectedPersons(selected);
  }
  clearSelection = item => {
    let stateArr = this.state.selected;
    let index;
    index = stateArr.indexOf(item);
    stateArr.splice(index, 1);
    this.setState({ selected: stateArr });
  };
  getCheckItems = (data, index) => {
    let checkedItems = {
      ...this.state.checkedItems,
      [index]: data,
    };
    this.setState({ checkedItems: checkedItems[0] });
    this.props.updatedSelectedPersons(checkedItems);
  };
  render() {
    const { onOk, onCancel, isFetching } = this.props;
    const { searchStr, selected, searchedStudent } = this.state;
    return (
      <div class="border-0">
        <div class="w-100 mt-4">
          <div
          // class="form-group has-search"
          >
            <input
              type="text"
              value={searchStr}
              // class="form-control shadow-sm"
              placeholder="Search by name"
              onChange={this.studentSearch}
              ref={ref => (this.input = ref)}
              style={{
                width: '100%',
                height: '40px',
                border: '0',
                borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                borderRadius: '0',
                WebkitAppearance: 'none',
                padding: '6px 30px 6px 0px',
                boxShadow: 'none',
                appearance: 'none',
                MozAppearance: 'none',
                MsAppearance: 'none',
                color: '#000',
                paddingLeft: '10px',
                marginBottom: '10px',
              }}
            />
          </div>
        </div>
        {/* {localStorage.getItem('isStaffMobileRegistered') != "null" &&
          !JSON.parse(localStorage.getItem('isStaffMobileRegistered')) && (
            <div>
              <p className="font-italic" style={{ fontWeight: '600', color: 'red' }}>
                <img class="mr-1" src="images/warning.svg" />
                You are required to be registered as an advisor on the mobile app to send in-app
                text messages.
              </p>
            </div>
          )} */}
        {/* <div className="text-left mt-4" style={{ fontSize: '16px', color: '#515151' }}>
          Selected ( {this.state.checkedItems && this.state.checkedItems.length} )
        </div> */}

        <div className="m-0 p-4">
          {
            <>
              <div
              // class="enrollment-form"
              // style={{ width: '100%', height: '200px', overflowY: 'auto' }}
              >
                {/* <Loader2 loading={isFetching} /> */}

                {this.state.studentData &&
                  this.state.studentData.map((item, index) => {
                    return (
                      this.state.studentData &&
                      this.state.studentData[0] &&
                      this.state.studentData[0].children && (
                        <MultipleCategoryCheckboxAll2
                          data={item}
                          index={index}
                          getCheckItems={this.getCheckItems}
                          getSelectAllValue={this.props.getSelectAllValue}
                          selectedPersons={this.props.selectedPersons}
                          isFetching={isFetching}
                          // defaultValue={
                          //   sessionStorage.getItem('timelineFilterMultiCheckbox') &&
                          //   JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[index] &&
                          //   JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[index]
                          //     .length > 0
                          //     ? JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[index]
                          //     : []
                          // }
                        />
                      )
                    );
                  })}
                {/* {this.state.studentData &&
                  this.state.studentData[0] &&
                  this.state.studentData[0].children &&
                  this.state.studentData[0].children.length <= 0 && (
                    <div style={{ fontSize: '14px', fontWeight: '700' }} className="text-center">
                      No Matching Record Found
                    </div>
                  )} */}
                {/* {searchedStudent.length > 0 ? (
                searchedStudent.map((item, index) => {
                  return (
                    <div className="col-12 d-inline-block px-0">
                      <div
                        className="d-flex justify-content-between m-1 align-items-center"
                        style={{
                          // boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.06)',
                          borderBottom: '1px solid #EDEDED',
                          paddingBottom: '10px',
                          marginBottom: '5px',
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="pr-3">
                            <Checkbox
                              onChange={e => {
                                this.onCheckBoxChange(e, item);
                              }}
                              value={index}
                            />
                          </div>
                          <Avatar
                            size="large"
                            className="mr-2"
                            src={item.ProfileImgUrl || _blank}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '14px', fontWeight: '700' }}>
                              {item.DisplayName}
                            </span>
                            <span
                              style={{
                                color: '#515151',
                                fontSize: '12px',
                              }}
                            >
                              {' '}
                              {item.JoinedDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="col-12">
                    <div style={{ fontSize: '14px', fontWeight: '700' }} className="text-center">
                      No Record
                    </div>
                  </div>
                </>
              )} */}
              </div>
            </>
          }
        </div>

        <div className="">
          <div
            class=""
            style={
              {
                // maxHeight: '140px',
                // overflowY: 'auto',
                // border: '1px solid #C4C4C4',
                // borderRadius: '4px',
              }
            }
          >
            {/* {this.state.studentData &&
              this.state.studentData[0] &&
              this.state.studentData[0].children &&
              this.state.studentData[0].children.map(item => {
                if (this.state.checkedItems.includes[item.UserGuid]) {
                  return (
                    <>
                      <div className="d-inline-flex mr-2">
                        <div
                          style={{
                            background: '#F8F8F8',
                            borderRadius: '26.5px',
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            padding: '4px',
                          }}
                        >
                          <Avatar className="mr-2" src={item.ProfileImgUrl || _blank} />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '13px' }}>{item.DisplayName}</span>
                          </div>
                          <Tooltip placement="top" title="Remove">
                            <span
                              class="delete-course"
                              style={{ margin: '0px 0.5rem' }}
                              onClick={() => {
                                this.clearSelection(item);
                              }}
                            >
                              <img
                                src="images/close_icon_grey.svg"
                                alt="Barcelona Bootcamp"
                                width="20"
                              />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return null;
                }
              })} */}

            {/* {this.state.checkedItems.length > 0 &&
              this.state.checkedItems.map(item => {
                debugger;
                debugger;
                return (
                  <>
                    <div className="d-inline-flex mr-2">
                      <div
                        style={{
                          background: '#F8F8F8',
                          borderRadius: '26.5px',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          padding: '4px',
                        }}
                      >
                        <Avatar className="mr-2" src={item.ProfileImgUrl || _blank} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontSize: '13px' }}>{item.DisplayName}</span>
                        </div>
                        <Tooltip placement="top" title="Remove">
                          <span
                            class="delete-course"
                            style={{ margin: '0px 0.5rem' }}
                            onClick={() => {
                              this.clearSelection(item);
                            }}
                          >
                            <img
                              src="images/close_icon_grey.svg"
                              alt="Barcelona Bootcamp"
                              width="20"
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                );
              })} */}
          </div>
        </div>

        {/* <input onClick={onCancel} type="button" class="btn mx-2" value="Cancel" />
        <input
          type="button"
          class="btn mx-2"
          value="Save"
          onClick={() => {
            this.handelGui();
            onOk();
          }}
        /> */}
      </div>
    );
  }
}

const mapStateToPropsGroupStudent = ({ app_subscribers_user_search, broadcast_user_list_get }) => {
  console.log(broadcast_user_list_get, 'broadcast_user_list_get');
  let searchedStudent = [];
  let searchStr;
  const { data, isFetching } = app_subscribers_user_search;
  const { data: broadcastData, isFetching: isBroadcastFetching } = broadcast_user_list_get;
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    // searchedStudent = Object.values(data.data) || [];
    // searchStr = data.searchStr || '';
    if (app_subscribers_user_search.data && app_subscribers_user_search.data.searchStr) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else {
      let resObj;
      if ('searchStr' in data) {
        resObj = Object.values(data.data);
      } else {
        resObj = Object.values(data);
      }
      searchedStudent = resObj || [];
    }
  } else if (broadcast_user_list_get && broadcast_user_list_get.data) {
    // searchedStudent = Object.values(data.data) || [];
    // searchStr = data.searchStr || '';
    if (broadcast_user_list_get.data && broadcast_user_list_get.data.searchStr) {
      searchedStudent = Object.values(broadcastData.data) || [];
      searchStr = broadcastData.searchStr;
    } else {
      let resObj;
      if ('searchStr' in broadcastData) {
        resObj = Object.values(broadcastData.data);
      } else {
        resObj = Object.values(broadcastData);
      }
      searchedStudent = resObj || [];
    }
  }

  return {
    searchedStudent,
    isFetching: isFetching || isBroadcastFetching,
    searchStr,
  };
};

export default connect(mapStateToPropsGroupStudent)(ReduxSelectGroupStudentModal);
