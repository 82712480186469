import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { Tag, Modal, Tooltip, Avatar } from 'antd';
// import Loader, { Loader2 } from '../../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import CustomModal from '../../../../../core/modal';
import AntdModal from '../../../../../Components/CustomModal';
import { ProcessCombos } from '../../../../../store/comboConfig';
import { getDate, stringDate } from '../../../../../util/date';
import PersistTableSort from '../../../../../component/persistTableSort';

import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
} from '../../../../../component/custom-redux-components/index.js';
import {
  getPatientDetail,
  addClientInsuranceEligibility,
  editClientInsuranceEligibility,
  deleteClientInsuranceEligibility,
} from '../../../../../store/actions';
import ReduxEligibilityForm from './eligibilityFormModal';

class ManageEligibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewEligibilityModal: false,
      isModelOpen: false,
      viewModalType: 'update',
      ClientInsuranceEligibility: props.ClientInsuranceEligibility || [],
      ClientInsuranceRow: props.ClientInsuranceRow || [],
      BreathalyzerData: {},
      columns: [
        {
          title: 'Start Date',
          dataIndex: 'StartDateDisplay',
          key: 'StartDate',
          isPrint: true,
          sorter: (a, b) => isComparer(a.StartDate, b.StartDate),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'End Date',
          dataIndex: 'EndDateDisplay',
          key: 'EndDate',
          isPrint: true,
          sorter: (a, b) => isComparer(a.EndDate, b.EndDate),
          sortDirections: ['descend', 'ascend'],
        },

        {
          title: 'Insurance Priority',
          dataIndex: 'InsurancePriority',
          key: 'InsurancePriority',
          isPrint: true,
          sorter: (a, b) => isComparer(a.InsurancePriority, b.InsurancePriority),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => (
            <>
              <span className={record.ResultClassBlock}>{text}</span>
            </>
          ),
        },
        {
          title: 'Action',
          key: 'x',
          render: (text, record, index) => (
            <div class="tools-grid">
              <div className="mr-4">
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      class="start-btn"
                      onClick={onClick}
                      data-toggle="modal"
                      data-target="#updateInsurancePriority"
                    >
                      <Tooltip placement="top" title="Edit">
                        <img src="images/shape.svg" width="14px" style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </span>
                  )}
                  ModalContent={props => (
                    <ReduxEligibilityForm
                      {...props}
                      InsurancePriority={this.props.InsurancePriority}
                      onSaveClick={this.onSubmit}
                      // initialValues={this.state.record}
                      recordValues={record}
                      viewModalType="update"
                    />
                  )}
                />
              </div>
              <div className="">
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      class="delete-btn"
                      onClick={onClick}
                      data-toggle="modal"
                      data-target="#delete-eligibility"
                    >
                      <Tooltip placement="top" title="Delete">
                        <p>
                          <i class="far fa-trash-alt"></i>
                        </p>
                      </Tooltip>
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to delete this Client Insurance Eligibility?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            onOk();
                            this.props.dispatch(
                              deleteClientInsuranceEligibility({
                                json: JSON.stringify({
                                  ClientInsuranceEligibility: [
                                    {
                                      ClientInsuranceEligibilityId:
                                        record.ClientInsuranceEligibilityId,
                                    },
                                  ],
                                }),
                              }),
                            );
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    // var result = ProcessCombos('InsurancePriority');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
    // this.props.dispatch(
    //   getBreathalyzerTestResult({
    //     Json: JSON.stringify({
    //       Breathalyzer: [
    //         {
    //           UserGuid: this.props.UserGuid,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ IsInserted, IsDeleted, IsUpdated }) {
    if (IsInserted && IsInserted != this.props.IsInserted) {
      this.toggle('Insurance Priority Added');
    }
    if (IsDeleted && IsDeleted != this.props.IsDeleted) {
      this.toggle('Insurance Priority Deleted');
    }
    if (IsUpdated && IsUpdated != this.props.IsUpdated) {
      this.toggle('Insurance Priority Updated');
    }
  }
  onSubmit = (StartDate, EndDate, InsurancePriorityId, viewModalType, recordValues) => {
    const { ClientInsuranceRow, ClientInsuranceEligibility } = this.props;
    if (viewModalType === 'update') {
      this.props.dispatch(
        editClientInsuranceEligibility({
          Json: JSON.stringify({
            ClientInsuranceEligibility: [
              {
                ClientInsuranceEligibilityId: recordValues.ClientInsuranceEligibilityId,
                ClientInsuranceId: recordValues.ClientInsuranceId,
                StartDate: StartDate ? stringDate(StartDate) : null,
                EndDate: EndDate ? stringDate(EndDate) : null,
                InsurancePriorityId: InsurancePriorityId,
              },
            ],
          }),
        }),
      );
    } else {
      this.props.dispatch(
        addClientInsuranceEligibility({
          Json: JSON.stringify({
            ClientInsuranceEligibility: [
              {
                ClientInsuranceId: ClientInsuranceRow.ClientInsuranceId,
                StartDate: StartDate ? stringDate(StartDate) : null,
                EndDate: EndDate ? stringDate(EndDate) : null,
                InsurancePriorityId: InsurancePriorityId,
              },
            ],
          }),
        }),
      );
    }
  };
  render() {
    const { viewEligibilityModal, ClientInsuranceEligibility, ClientInsuranceRow } = this.state;
    const { isFetching, InsurancePriority } = this.props;
    return (
      <div>
        {/* <Loader2 loading={isFetching} /> */}
        <AntdModal
          ModalButton={({ onClick }) => (
            <span
              class="delete-btn"
              onClick={onClick}
              data-toggle="modal"
              data-target="#addInsurancePriority"
            >
              <span class="add-contact">
                <label> + Add Eligibility</label>
              </span>
            </span>
          )}
          ModalContent={props => (
            <ReduxEligibilityForm
              {...props}
              InsurancePriority={InsurancePriority}
              onSaveClick={this.onSubmit}
              // initialValues={this.state.record}
              // recordValues={this.state.record}
              viewModalType="new"
            />
          )}
        />
        {true ? (
          <div className="row align-items-center">
            <div class="col-12 pb-1">
              <div style={{ height: '220px', overflowY: 'scroll' }} className="time-line-table">
                <PersistTableSort
                  name="ClientInsuranceEligibility"
                  columns={this.state.columns}
                  dataSource={ClientInsuranceEligibility}
                  size={'middle'}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex align-items-center justify-content-center"
              style={{ height: '276px' }}
            >
              <h5 className="text-center">No data</h5>
            </div>
          </div>
        )}
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(
              getPatientDetail({
                json: JSON.stringify({
                  Client: [
                    {
                      ClientId: this.props.clientId,
                    },
                  ],
                }),
              }),
            );
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
        {/* <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={'Delete_Confirmation_Msg'}
          yesAction={() => {
            // this.Removetoggle();
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            // this.Removetoggle();
          }}
        /> */}
        {/* <Modal
          zIndex={200}
          visible={viewEligibilityModal}
          footer={null}
          closable={false}
          destroyOnClose={true}
        >
          <ReduxEligibilityForm
            //   MediaGuid={MediaGuid}
            InsurancePriority={InsurancePriority}
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.record}
            viewModalType={this.state.viewModalType}
            onCancel={() => {
              this.setState({
                viewEligibilityModal: false,
              });
            }}
          />
        </Modal> */}
      </div>
    );
  }
}
const mapStateToProps = ({
  combos,
  add_client_insurance_eligibility,
  edit_client_insurance_eligibility,
  delete_client_insurance_eligibility,
}) => {
  // const { data, error, isFetching } = edit_client_insurance_eligibility;
  // const { data, error, isFetching } = add_client_insurance_eligibility;
  let IsInserted = false;
  let IsUpdated = false;
  let IsDeleted = false;
  let InsurancePriority = [];

  if (add_client_insurance_eligibility.data && add_client_insurance_eligibility.data.success) {
    IsInserted = true;
  }
  if (
    delete_client_insurance_eligibility.data &&
    delete_client_insurance_eligibility.data.success
  ) {
    IsDeleted = true;
  }
  if (edit_client_insurance_eligibility.data && edit_client_insurance_eligibility.data.success) {
    IsUpdated = true;
  }
  if (combos.data && combos.data['InsurancePriority']) {
    InsurancePriority = combos.data['InsurancePriority'];
  }
  return {
    InsurancePriority,
    IsInserted,
    IsDeleted,
    IsUpdated,
    // isFetching,
  };
};

export default connect(mapStateToProps)(ManageEligibility);
