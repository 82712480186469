import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer } from 'antd';
// import AIAugmentedSummary from './moodDrawerContent/AIAugmentedSummary';
import AIAugmentedSummary from '../customScreens/surveyResponse/AIAugmentedSummary';

export default function Mood(props) {
  const { Mood, UserGuid, studentId } = props;
  const [isMoodDrawerOpen, setIsMoodDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    // this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
    setIsMoodDrawerOpen(prevState => !prevState);
  };
  const { AugmentedAIStatusData, isLicenseNotify } = props;

  return (
    <>
      {Mood ? (
        Mood.map((item, index) => {
          const { CreatedDate, ImageUrl, Mood, Reason } = item;
          return (
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: '7px',
                boxShadow: '0px 11px 16px rgba(0, 0, 0, 0.0620356)',
              }}
              className="p-3 d-flex justify-content-between align-items-center mb-3"
            >
              <div className="d-flex justify-content-center align-items-center">
                <img src={ImageUrl} alt="Mood" />
                <div className="ml-3">
                  <h6 className="mb-0">{Reason}</h6>
                  <p className="mb-0">{Mood}</p>
                  <p className="mb-0">{CreatedDate}</p>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {AugmentedAIStatusData &&
                  AugmentedAIStatusData.LicenseStatusId != 1 &&
                  AugmentedAIStatusData.LicenseStatusId != 3 &&
                  AugmentedAIStatusData.LicenseStatusId != 5 && (
                    <img
                      src="images/gpt-ai.svg"
                      style={{ cursor: 'pointer' }}
                      onClick={toggleDrawer}
                      className="mr-3"
                    />
                  )}
                <div
                  style={{
                    backgroundColor: '#E8E8E8',
                    padding: '10px',
                    height: 'fit-content',
                    cursor: 'pointer',
                  }}
                  className="d-flex justify-content-center align-items-center"
                  onClick={
                    () => {
                      if (UserGuid) {
                        return props.history.push(
                          `/timetravel/?subscriberId=${UserGuid}&TTFilter=26`,
                        );
                      } else {
                        return props.history.push(
                          `/client-timetravel/?studentid=${studentId}&TTFilter=26`,
                        );
                      }
                    }
                    //
                  }
                >
                  <img
                    src="images/timeline/timeline.svg"
                    style={{ width: '19px', height: '18px' }}
                    alt="timeline"
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          className="d-flex align-items-center justify-content-center mb-3"
          style={{
            backgroundColor: '#F8F8F8',
            height: '102px',
            borderRadius: '7px',
            boxShadow: '0px 11px 16px rgba(0, 0, 0, 0.0620356)',
          }}
        >
          <p className="mb-0 text-center" style={{ color: '#9A9A9A' }}>
            No Emotions Data have been Selected Yet
          </p>
        </div>
      )}

      <Drawer
        className="custom-drawer journal-augmenting"
        title="Augment with AI"
        placement="right"
        open={isMoodDrawerOpen}
        width={800}
        bodyStyle={{ paddingBottom: 80 }}
        closable={true}
        destroyOnClose={true}
        onClose={() => {
          // this.props.dispatch(resetSurveyAnswersGet());
          toggleDrawer();
        }}
      >
        <AIAugmentedSummary
          ClientId={studentId}
          toggleDrawer={toggleDrawer}
          isMoodAugment={true}
          UserGuid={UserGuid}
          // ColData={surveyanswersdatacol}
          // augmentId={this.state.augmentId}
          // changeAugmentId={changeAugmentId}
          // activekey={activekey}
          // UserGuid={props.UserGuid}
        />
      </Drawer>
    </>
  );
}
