import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProviderList from './providerList';
import ProviderProfile from './providerProfile';

class ProviderEnrollment extends Component {
  constructor(props) {
    super(props);
    let params = new URLSearchParams(props.location.search);
    let tab = params.get('tab');
    let isListView = true;
    if (tab == 'ProviderManagement/providerProfile') {
      isListView = false;
    }
    this.state = {
      isListView: isListView,
    };
  }
  toggleView = () => {
    this.setState(prevState => ({
      isListView: !prevState.isListView,
    }));
  };
  render() {
    return (
      <>
        <div class="main enrollment-page mb-5">
          <div class="container-fluid px-5">
            {this.state.isListView ? (
              <div className="row">
                <ProviderList {...this.props} toggleView={this.toggleView} />
              </div>
            ) : (
              <ProviderProfile {...this.props} toggleView={this.toggleView} />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default ProviderEnrollment;
