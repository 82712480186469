import React, { Component, PropTypes } from 'react';
import Select from 'react-select';
// import { isEmpty } from 'lodash';

const getOptionLabel = option => {
  if (option.Icon) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={`images/icons/${option.Icon}.svg`} />
        <span style={{ marginLeft: 5 }}>{option.Text}</span>
      </div>
    );
  } else {
    return option.Text;
  }
};
const getOptionValue = (option, isMulti) => {
  if (isMulti) {
    if (option[0]) {
      var value = [];
      for (var o of option) {
        value.push(o);
      }
      return value;
    } else {
      return [];
    }
  }

  return option.Value;
};

/**
 * Converts simple value to object for consumption by react-select
 * @param {any} value simple value such as id or option such as {Value: 5}
 * @param {*} options options
 */
const getValue = (value, options, isMulti) => {
  let match;
  if (value === undefined || options === undefined) {
    return '';
  }
  if (!options) {
    return value;
  }

  if (typeof value === 'object') {
    return value;
  }
  if (isMulti) {
    let val = value.split(',');

    match = options.filter(f => val.includes(f.Value && f.Value.toString()));
  } else {
    match = options.find(t => t.Value === value);
  }
  if (match) {
    return match;
  }
  return value;
};

class CustomSelect extends Component {
  render() {
    return <Select {...this.props} />;
  }
}

CustomSelect.defaultProps = {
  className: 'basic-single',
  classNamePrefix: 'select',
  getOptionLabel: getOptionLabel,
  getOptionValue: getOptionValue,
};

export default CustomSelect;

const ReduxSelect = ({
  input,
  options,
  name,
  id,
  value,
  onChange,
  onBlur,
  meta,
  errorMessage,
  disabled,
  tabIndex,
  menuPlacement,
  isMulti,
  focus,
  isClearable,
  placeholder: label,
  fieldName,
  viewMode,
  formatOptionLabel,
}) => {
  var { touched, error, warning, active } = meta || {};
  if (input) {
    value = input.value;
    // value = isNaN(input.value) || !typeof input.value === 'string' ? null : input.value;
    onChange = input.onChange;

    onBlur = () => input.onBlur;
  }

  const getValues = values => {
    if (typeof values == 'string' && values !== '') {
      return values.split(/,/g);
    } else if (typeof values == 'object') {
      return values;
    }
    return [];
  };

  const values = getValues(value);
  return (
    <div style={{ position: 'relative' }}>
      {/* {value == 0 && value != null && value ? ( */}
      {/* where ever we are using multi select if we remove all options still showing 2 placeholders for that we added a condition !isMulti */}
      {!isMulti && value == 0 && value != null && value ? (
        <label
          className="placeholder-label"
          style={{
            zIndex: 1,
            fontSize: !!disabled || value || active ? '14px' : '16px',
            top: !!disabled || value || active ? '-5px' : '24px',
            // left: !!disabled || value || active ? '0' : '0',
            position: 'absolute',
          }}
        >
          {label}
        </label>
      ) : null}
      {value != 0 && value != null && value ? (
        <label
          className="placeholder-label"
          style={{
            zIndex: 1,
            fontSize: !!disabled || value || active ? '14px' : '16px',
            top: !!disabled || value || active ? '-5px' : '24px',
            // left: !!disabled || value || active ? '0' : '0',
            position: 'absolute',
            background: isMulti && !disabled ? 'white' : '',
          }}
        >
          {label}
        </label>
      ) : null}
      {viewMode && isMulti ? (
        <div style={{ paddingTop: '10px', minHeight: '40px', paddingBottom: '15px' }}>
          {values &&
            options &&
            values.map(item =>
              options.map(optItem => {
                if (item == optItem.Value) return optItem.Text + ',';
              }),
            )}
        </div>
      ) : (
        <CustomSelect
          {...input}
          isMulti={isMulti}
          tabIndex={tabIndex}
          id={id || (input && input.name) || label}
          name={name}
          options={options}
          isDisabled={!!disabled}
          hideSelectedOptions={true}
          formatOptionLabel={formatOptionLabel}
          menuPlacement={menuPlacement || 'auto'}
          value={getValue(value, options, isMulti)} /* Convert simple value to object */
          onChange={value => {
            if (value) {
              return onChange(getOptionValue(value, isMulti));
            } else {
              return onChange(null);
            }
          }} /* Redux handling and convert object to simple value */
          onBlur={onBlur}
          isClearable={isClearable}
          autoFocus={!!focus}
          placeholder={label ? label : ''}
        />
      )}
      {touched &&
        ((error && (
          <span className="error-text">
            {fieldName} {error}
          </span>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

const ReduxTextSelect = ({
  input,
  options,
  name,
  id,
  value,
  onChange,
  onBlur,
  meta,
  errorMessage,
  disabled,
  tabIndex,
  menuPlacement,
  isMulti,
  focus,
  isClearable,
}) => {
  var { touched, error, warning } = meta || {};

  if (input) {
    value = input.value;
    onChange = input.onChange;
    onBlur = () => input.onBlur;
  }

  var selectOptions = [];

  if (!options) {
    options = [];
  }
  for (var item of options) {
    if (item.Text == value) {
      selectOptions.push(
        <option value={item.Text} selected>
          {item.Text}
        </option>,
      );
    } else {
      selectOptions.push(<option value={item.Text}>{item.Text}</option>);
    }
  }

  return (
    <div>
      <select class="simple-basic-single" onChange={onChange}>
        {selectOptions}
      </select>
      {touched &&
        ((error && <span className="error-text">{error}</span>) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

const SuffixReduxSelect = props => {
  const data = [
    { Value: 0, Text: ' <Select>' },
    { Value: 1, Text: 'Sr.' },
    { Value: 2, Text: 'Jr.' },
  ];
  return <ReduxSelect {...props} options={data} />;
};

const FrequencyReduxSelect = props => {
  const data = [
    { Value: 0, Text: ' <Select>' },
    { Value: 1, Text: 'One Time' },
    { Value: 2, Text: 'Multiple Times' },
  ];
  return <ReduxSelect {...props} options={data} />;
};

export { ReduxSelect, ReduxTextSelect, FrequencyReduxSelect };
