import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getAllCallSession,
  addCallSession,
  resetAddCallSession,
  getAllVacation,
  addVacationDays,
  resetAddVacationDays,
} from '../store/actions';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { stringDate } from '../util/date';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
} from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import { Tag, Modal } from 'antd';
import { Field, reduxForm, formValueSelector, FieldArray, getFormSyncErrors } from 'redux-form';
import ListRenderer from '../Components/listRenderer';

// const success = message => {
//   Modal.info({
//     content: message,
//   });
// };

const BlockFieldArray = ({ fields }) => (
  <div className="row enrollment-form">
    <div className="col-12">
      {fields.map((item, index) => (
        <div class="field-array-item">
          <div key={index} className="row m-0">
            <div class="col-6 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <Field
                    // name={`${item}.startDate`}
                    name="startDate"
                    type="text"
                    label="Start Date"
                    errorMessage="Start Date Line 1 Required"
                    component={ReduxDateInput}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-6 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <Field
                    // name={`${item}.endDate`}
                    name="endDate"
                    label="End Date"
                    type="text"
                    component={ReduxDateInput}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <Field
                  // name={`${substanceUseProfile}.notes`}
                  // name={`${item}.reason`}
                  name="reason"
                  type="textarea"
                  component={ReduxInput}
                />
                <label class="placeholder-label">Reason</label>
              </div>
            </div>
          </div>
          {/* <div className="col-12 text-right mb-3">
            <a
              class="delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 1) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div> */}
        </div>
      ))}
    </div>
    {/* <div class="col-12 text-right pr-4">
      <a class="add-contact" onClick={() => fields.push({})}>
        {fields.length >= 1 && <label> + Add More</label>}
        {fields.length == 0 && <label> + Add</label>}
      </a>
    </div> */}
  </div>
);

class BlockDaysModal extends Component {
  constructor(props) {
    super(props);
    const { startDate, endDate } = props;
    this.state = {
      startDate: startDate || null,
      endDate: endDate || null,
      reason: '',
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  resetState = () => this.setState({ startDate: '', endDate: '', filterCenterId: '' });

  componentDidMount() {
    this.props.dispatch(getAllVacation({}));
  }
  componentWillReceiveProps({ isAddVactionDays }) {
    if (isAddVactionDays) {
      this.props.dispatch(resetAddVacationDays());
      this.props.dispatch(getAllVacation({}));
      // this.setState({ errorMessage });
      // success(errorMessage);
    }
  }
  addBlockDays = value => {
    this.props.dispatch(
      addVacationDays({
        StartDate: stringDate(value.startDate),
        EndDate: stringDate(value.endDate),
        Reason: value.reason,
      }),
    );
    // this.props.onOk();
  };
  render() {
    const { onOk, onCancel, allVacations, handleSubmit, isFetching } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Block Days</h4>
          <span className="pl-3">lscsc sdsdsdas asdsdasdsa</span>
        </div>
        <div class="modal-body">
          <form onSubmit={handleSubmit(this.addBlockDays)}>
            <FieldArray name="blockFieldArray" component={BlockFieldArray} />
            <div class="row m-0 justify-content-around">
              {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold "> Call Session Notes</span>
            </div> */}
              {/* <span>{errorMessage}</span> */}
              <div class="col-12 col-md-12" style={{ textAlign: 'right' }}>
                <button type="submit" class="btn black-btn">
                  Save
                </button>
              </div>
            </div>
          </form>
          {/* <BlockDaysRange handleSubmit={this.props.handleSubmit} /> */}
          <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Block Days List</span>
            </div>
            <div
              class="col-12 col-md-12 enrollment-form"
              style={{ height: '200px', overflowY: 'scroll' }}
            >
              <Loader2 loading={isFetching} />
              {allVacations &&
                allVacations.map(item => {
                  return (
                    <div className="shift-log-detail">
                      <div class="text-left mb-1 d-flex justify-content-between">
                        <div>
                          {/* <span class="font-weight-bold ">From : </span> */}
                          <span>{item.StartDate} </span>
                          <span class="font-weight-bold ">To : </span>
                          <span>{item.EndDate} </span>
                        </div>
                        <div>
                          <span class="font-weight-bold "></span>
                          <span>{item.Reason} </span>
                        </div>
                        <div>
                          <a
                            class="delete-contract"
                            onClick={() => {
                              this.props.dispatch(
                                addVacationDays({
                                  AdvisorVacationId: item.AdvisorVacationId,
                                }),
                              );
                            }}
                          >
                            <i class="fas fa-trash-alt fa-lg" />
                          </a>
                        </div>
                      </div>
                      {/* <div class="text-left mb-1">
                        <span class="font-weight-bold ">Shift Note : </span>
                        <span>{item.Reason} </span>
                        <span>{item.AdvisorVacationId} </span>
                      </div> */}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
        </div> */}
      </div>
    );
  }
}
const mapStateToPropsVacation = ({ get_all_vacation, add_vacation_days }) => {
  var allVacations = [];
  let isAddVactionDays = false;
  // let errorMessage = '';
  const { data, isFetching } = get_all_vacation;
  if (get_all_vacation && get_all_vacation.data) {
    allVacations = get_all_vacation.data.Advisor;
  }
  if (add_vacation_days.data && add_vacation_days.data.success) {
    isAddVactionDays = add_vacation_days.data.success;
    // errorMessage = add_vacation_days.data.result;
  }
  return {
    allVacations,
    isFetching: isFetching,
    isAddVactionDays,
    // errorMessage,
  };
};
const BlockDaysReduxModal = reduxForm({
  form: 'blockDaysModal-form',
})(connect(mapStateToPropsVacation)(withRouter(BlockDaysModal)));

class CallSessionAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callSession: {
        blockFieldArray: [{}],
      },
      allCallSession: [],
      clickedWeekDay: '',
      clickedSlotIndex: '',
      clickedRow: [{}],
      TabName:
        this.props.location.pathname == '/callSession' ||
        this.props.location.pathname == '/callSession/inHouse'
          ? 'CallSession'
          : 'Schedule Call',
    };
  }
  componentDidMount() {
    this.props.dispatch(getAllCallSession({}));
  }
  componentWillReceiveProps({ isAddCallSession, allCallSession }) {
    if (isAddCallSession) {
      this.props.dispatch(resetAddCallSession());
      this.updateCellState();
    }
    if (allCallSession !== this.props.allCallSession) {
      this.setState({
        allCallSession: allCallSession,
      });
    }
  }
  updateCellState = () => {
    let { allCallSession, clickedRow, clickedWeekDay, clickedSlotIndex } = this.state;
    allCallSession[clickedSlotIndex][clickedWeekDay] == 0
      ? (allCallSession[clickedSlotIndex][clickedWeekDay] = 1)
      : (allCallSession[clickedSlotIndex][clickedWeekDay] = 0);
    this.setState();
  };
  toggleSlot = (weekNumber, record, weekDay) => {
    this.props.dispatch(
      addCallSession({
        WeekNumber: weekNumber,
        TimeSlotId: record.TimeSlotId,
      }),
    );

    this.state.clickedRow = record;
    this.state.clickedWeekDay = weekDay;
    this.state.clickedSlotIndex = record.TimeSlotId - 1;
  };
  render() {
    let columns = [
      {
        title: 'Slot Time',
        dataIndex: 'SlotTime',
        key: 'SlotTime',
        align: 'center',
        width: 160,
        render: text => {
          return text;
        },
      },
      {
        title: 'Mon',
        key: 'WK_Monday',
        dataIndex: 'WK_Monday',
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            // onDoubleClick
            onClick: event => {
              this.toggleSlot(2, record, 'WK_Monday');
            }, // double click row
          };
        },
        render: text => {
          let color = text == 0 ? 'red' : 'green';
          return (
            <Tag color={color} key={text}>
              {text == 0 ? 'NOT AVAILABLE' : 'AVAILABLE'}
            </Tag>
          );
        },
      },
      {
        title: 'Tue',
        key: 'WK_Tuesday',
        dataIndex: 'WK_Tuesday',
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              this.toggleSlot(3, record, 'WK_Tuesday');
            }, // double click row
          };
        },
        render: text => {
          let color = text == 0 ? 'red' : 'green';
          return (
            <Tag color={color} key={text}>
              {text == 0 ? 'NOT AVAILABLE' : 'AVAILABLE'}
            </Tag>
          );
        },
      },
      {
        title: 'Wed',
        key: 'WK_Wenesday',
        dataIndex: 'WK_Wenesday',
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              this.toggleSlot(4, record, 'WK_Wenesday');
            }, // double click row
          };
        },
        render: text => {
          let color = text == 0 ? 'red' : 'green';
          return (
            <Tag color={color} key={text}>
              {text == 0 ? 'NOT AVAILABLE' : 'AVAILABLE'}
            </Tag>
          );
        },
      },
      {
        title: 'Thu',
        key: 'WK_Thursday',
        dataIndex: 'WK_Thursday',
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              this.toggleSlot(5, record, 'WK_Thursday');
            }, // double click row
          };
        },
        render: text => {
          let color = text == 0 ? 'red' : 'green';
          return (
            <Tag color={color} key={text}>
              {text == 0 ? 'NOT AVAILABLE' : 'AVAILABLE'}
            </Tag>
          );
        },
      },
      {
        title: 'Fri',
        key: 'WK_Friday',
        dataIndex: 'WK_Friday',
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              this.toggleSlot(6, record, 'WK_Friday');
            }, // double click row
          };
        },
        render: text => {
          let color = text == 0 ? 'red' : 'green';
          return (
            <Tag color={color} key={text}>
              {text == 0 ? 'NOT AVAILABLE' : 'AVAILABLE'}
            </Tag>
          );
        },
      },
      {
        title: 'Sat',
        key: 'WK_Saturday',
        dataIndex: 'WK_Saturday',
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              this.toggleSlot(7, record, 'WK_Saturday');
            }, // double click row
          };
        },
        render: text => {
          let color = text == 0 ? 'red' : 'green';
          return (
            <Tag color={color} key={text}>
              {text == 0 ? 'NOT AVAILABLE' : 'AVAILABLE'}
            </Tag>
          );
        },
      },
      {
        title: 'Sun',
        key: 'WK_Sunday',
        dataIndex: 'WK_Sunday',
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              this.toggleSlot(1, record, 'WK_Sunday');
            }, // double click row
          };
        },
        render: text => {
          let color = text == 0 ? 'red' : 'green';
          return (
            <Tag color={color} key={text}>
              {text == 0 ? 'NOT AVAILABLE' : 'AVAILABLE'}
            </Tag>
          );
        },
      },
    ];
    const { isFetching, history } = this.props;
    const { allCallSession, TabName } = this.state;
    return (
      <>
        {/* <Loader loading={isFetching} /> */}

        {/* <div class="row top-table-search mt-4 mb-4 align-items-end">
            <div class="col-12 col-md-3 col-lg-1">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>              
            </div>
            <div class="col-12 col-md-9 col-lg-11">
              <div class="preference-btn d-flex align-items-end justify-content-center">
                <div class="user-btnbox d-flex">
                  <a
                    class={`preference-link ${TabName === 'CallSession' ? 'active' : ''}`}
                    title=" Manage Call Session Availability"
                    style={{ width: '265px', marginTop: '20px' }}
                    onClick={() => history.push('/callSession')}
                  >
                    Manage Call Session Availability
                  </a>
                  <a
                    class={`preference-link ${TabName === 'Schedule Call' ? 'active' : ''}`}
                    title="Schedule Call"
                    style={{ width: '265px', marginTop: '20px' }}
                    onClick={() => history.push('/callSchedule')}
                  >
                    Schedule Call
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        <div class="row">
          <div class="col-12 mb-5">
            <div class="card border-0 ">
              <div class="col-12 card-body">
                <div class="row">
                  <div class="col-4 col-md-4">
                    <h6 className="d-inline">Clock Format :</h6>
                    <div class="col-4 d-inline">
                      <select
                        style={{ display: 'inherit', width: 'unset' }}
                        name="selectHour"
                        class="form-control"
                      >
                        <option value="1">24-hour</option>
                        <option value="2">12-hour</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-5 col-md-5">
                    <div className="d-flex">
                      <div className="mr-2 d-flex">
                        <span style={{ color: '#12c475', marginRight: '5px', fontWeight: '600' }}>
                          {' '}
                          Slot Available :{' '}
                        </span>
                        <span
                          style={{
                            backgroundColor: '#12c475',
                            display: 'inline-block',
                            width: '60px',
                            height: '20px',
                          }}
                        ></span>
                      </div>

                      <div className="mr-2 d-flex">
                        <span style={{ color: '#e87a7a', marginRight: '5px', fontWeight: '600' }}>
                          {' '}
                          Slot Not Available :{' '}
                        </span>
                        <span
                          style={{
                            backgroundColor: '#e87a7a',
                            display: 'inline-block',
                            width: '60px',
                            height: '20px',
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 col-md-3 text-right">
                    {/* <button className="btn btn-small btn-outline-primary">Block Days</button> */}
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <span className="btn btn-outline-primary" onClick={onClick}>
                          Block Days
                        </span>
                      )}
                      ModalContent={props => (
                        <BlockDaysReduxModal
                          {...props}
                          initialValues={this.state.callSession}
                          dispatch={this.props.dispatch}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 mt-2">
                    {/* <Loader2 loading={isFetching} /> */}
                    {/* <Bar data={barData} options={barOptions} width={300} height={100} /> */}
                    <Table
                      scroll={{ y: 450 }}
                      bordered
                      columns={columns}
                      dataSource={allCallSession && allCallSession}
                      pagination={false}
                      loading={isFetching}
                      // onRow={(record, rowIndex) => {
                      //   return {
                      //     onDoubleClick: event => {
                      //       this.toggleSlot(record, rowIndex);
                      //     }, // double click row
                      //   };
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ get_all_call_session, add_call_session }) => {
  var allCallSession = [];
  let isAddCallSession = false;
  const { data, isFetching } = get_all_call_session;
  if (get_all_call_session && get_all_call_session.data) {
    allCallSession = get_all_call_session.data.Advisor;
  }
  if (add_call_session.data && add_call_session.data.success) {
    isAddCallSession = add_call_session.data.success;
  }
  return {
    allCallSession,
    isFetching: isFetching || add_call_session.isFetching,
    isAddCallSession,
  };
};

export default connect(mapStateToProps)(withRouter(CallSessionAvailability));
