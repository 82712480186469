import React, { useEffect, useState } from 'react';
import renderHTML from 'react-render-html';
import { ProfilePicComponent } from '../../../Components/profilePicComponent';
import { Loader2 } from '../../../Components/Loader';
import { Tooltip } from 'antd';

const ViewFeedback = props => {
  // const [feedbackInfo, setFeedbackInfo] = useState({});
  // useEffect(() => {
  //   if (props.ResponseData) {
  //     setFeedbackInfo(props.ResponseData);
  //   }
  // }, [props.ResponseData]);
  const {
    ClientName,
    ClientImgUrl,
    Comment,
    FeedbackId,
    IsCareTeam,
    OverAllComment,
    PractitionerId,
    Reason,
    Score,
    StaffImgUrl,
    StaffName,
    SurveyAnswer,
    UpdatedOn,
  } = props.ResponseData || {};
  const ratingStar = [1, 2, 3, 4, 5];
  return (
    <div className="row">
      <Loader2 loading={props.isFetching} />
      <div className="col-12">
        <div className="view-feedback-header">
          <div className="profile">
            <div
              className="mr-2"
              style={{
                maxWidth: '50px',
                maxHeight: '50px',
              }}
            >
              <img
                src={ClientImgUrl}
                style={{
                  borderRadius: '10px',
                }}
              />
            </div>
            <div>
              <span>{ClientName}</span>
              <div className="d-flex">
                <span className="info mr-2">Rating </span>
                <img src="/images/icons/star_rating.svg" />
                <span className="ml-1" style={{ fontWeight: '600' }}>
                  {Score}
                </span>
              </div>
            </div>
          </div>
          <div className="reason">
            <p className="label">Reason</p>
            <p className="val">{Reason}</p>
          </div>
          <div className="staff">
            <p className="label">Care Team Member</p>
            {IsCareTeam ? (
              <div className="d-flex align-item-center">
                <div className="student-list-pic mr-2">
                  <ProfilePicComponent src={StaffImgUrl} width={30} />
                </div>
                <p className="val">{StaffName}</p>
              </div>
            ) : (
              <p className="val">NA</p>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 view-feedback-body">
        {IsCareTeam ? (
          <div className="row">
            <div className="col-12">
              <span className="title-label">Care Team Member Rating</span>
            </div>
            {SurveyAnswer &&
              SurveyAnswer.map((item, index) => (
                // const { Comment, Question, QuestionHelp, Score, Sequence } = item;
                <div className="col-12" key={index + 1}>
                  <div className="rating-box">
                    <div className="block">
                      <div className="care-detail">
                        <div className="d-1">
                          <div>
                            <span>{index + 1}</span> <span>{renderHTML(item.Question)}</span>
                          </div>
                        </div>
                        <div className="d-2 mb-2">
                          {ratingStar.map((rate, rateIndex) =>
                            rateIndex <= Score ? (
                              <img src="/images/icons/star_rating.svg" className="mr-1" />
                            ) : (
                              <img src="/images/icons/star_rating_blank.svg" className="mr-1" />
                            ),
                          )}
                        </div>
                        <div className="comment">
                          <span className="text">Comment</span>
                          <span className="val">{item.Comment}</span>
                        </div>
                      </div>
                      <div className="item">
                        <div
                          style={{
                            minWidth: '50px',
                            cursor: 'pointer',
                          }}
                        >
                          <Tooltip placement="topLeft" title={item.QuestionHelp}>
                            <img src="images/icons/tooltip_info.svg" />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="overall-comment">
                <span className="title-label">Overall Comment</span>
                <span className="value">{OverAllComment}</span>
                <span className="title-label">Updated on</span>
                <span className="value">{UpdatedOn}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ViewFeedback;
