import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import {
  Progress,
  Tooltip,
  Table,
  Slider,
  Collapse,
  Spin,
  Modal,
  Switch,
  Button,
  Drawer,
} from 'antd';
// import ListRenderer from '../Components/listRenderer';
// import CustomModal from '../Components/CustomModal';
// import CustomModal2 from '../core/modal';
// import { withRouter } from 'react-router-dom';

// import { DatePicker } from '../component/custom-redux-components';

export const AnyReactComponent = ({ name, image }) => (
  <div className="custom-marker" style={{ background: 'red', border: '1px solid yellow' }}>
    <div
      className="d-flex flex-column"
      style={{
        border: '3px solid rgba(0, 158, 247, 1)',
        background: '#d9d9d9',
        borderRadius: '50%',
        width: '75px',
        height: '75px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        backgroundImage: `url(${image})`,
        backgroundSize: 'contain',
      }}
    >
      {/* <img src={image} width={'40px'} /> */}
    </div>
  </div>
);

class ConcernedPersonLocation extends Component {
  render() {
    const { location } = this.props;
    const { Latitude, Longitude, ProfileImgUrl, DisplayName } = location;
    return (
      <>
        {Latitude && Longitude ? (
          <div className="concerned-person-location" style={{ height: '500px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo' }}
              defaultCenter={{
                lat: Latitude,
                lng: Longitude,
              }}
              defaultZoom={11}
            >
              <AnyReactComponent
                lat={Latitude}
                lng={Longitude}
                name={DisplayName}
                image={ProfileImgUrl}
              />
            </GoogleMapReact>
          </div>
        ) : (
          <>No location available</>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ app_user_live_location_get }) => {
  const { isFetching, error, data } = app_user_live_location_get;

  var location = {};
  if (data && data.MemberLocation && data.MemberLocation[0] && data.MemberLocation[0].Latitude) {
    location = {
      Latitude: data.MemberLocation[0].Latitude,
      Longitude: data.MemberLocation[0].Longitude,
      ProfileImgUrl: data.MemberLocation[0].ProfileImgUrl,
      DisplayName: data.MemberLocation[0].DisplayName,
    };
  }

  return {
    location,
  };
};
export default connect(mapStateToProps)(ConcernedPersonLocation);
