import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Drawer } from 'antd';
import FlaggedStatus from '../../EnterpriseClient/components/FlaggedStatus';
import ViewAnswerDrawer from '../../EnterpriseClient/pre-intake/dashboard/ViewAnswerTab';
// import ListRenderer from '../../../Components/listRenderer';
// import ViewScreeningAnswer from '../../EnterpriseClient/components/ViewScreeningAnswer';
// import { listSurveyQuestionAssessment } from '../../../store/actions';

class ClientScreening extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { EventJsonData, EventJson, MiscJson, StudentId, showAnswerDrawerDrawer } = this.props;
    const { visibleAnswerDrawer } = this.state;

    let {
      FlaggedCount,
      Role,
      ImageUrl,
      IsQualified,
      QualifiedCount,
      Status,
      UpdatedBy,
      UpdatedOn,
      SurveyId,
    } = EventJsonData || {};
    return (
      <div class="border-0 update-history-card">
        <div className="d-flex survey-event-details">
          <div className="screening-questions-answered-event w-100">
            <div className="note-event">
              <div className="added-by">
                <span className="added-text">Added by</span>
                <img src={ImageUrl || 'images/blank-profile.png'} alt="added by" />
                <div className="name-box">
                  <span className="name">{UpdatedBy}</span>
                  <span className="role">{Role}</span>
                </div>
              </div>
            </div>
            {/* <div>
                  <ClientRiskChart
                    ClientId={this.props.ClientId}
                    DateForSort={DateForSort}
                    ClientRisk={(ClientRisk && ClientRisk.toString()) || '0'}
                    IsHistory={true}
                  />
                </div> */}

            <div className="status d-flex">
              <FlaggedStatus FlaggedStatus={EventJsonData} />
              <button
                title="View Answers"
                class="survey-btn btn btn-outline-primary black-btn py-1"
                onClick={() => {
                  showAnswerDrawerDrawer(SurveyId, EventJsonData, 'Client Screening', MiscJson);
                }}
                style={{
                  display: 'inline',
                }}
              >
                View Answers{' '}
                <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                  <img src="images/view-answer.svg" alt="" width="21" />
                </figure>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ClientScreening);
