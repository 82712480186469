import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AntdModal from '../../../Components/CustomModal';
import SurveyAnswersModal from './SurveyAnswersModal';
import { preIntakeSurveyAnswersGet } from '../../../store/actions';

class ClientRiskChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSurveyAnswers = DateForSort => {
    this.props.dispatch(
      preIntakeSurveyAnswersGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { DateForSort } = this.props;
    return (
      <div className="d-flex align-items-center">
        <AntdModal
          style={{ top: 20 }}
          ModalButton={({ onClick }) => (
            <button
              title="Discharge"
              class="survey-btn btn btn-outline-primary black-btn py-1"
              onClick={() => {
                this.getSurveyAnswers(DateForSort);
                onClick();
              }}
              style={{
                display: 'inline',
              }}
            >
              View Answers{' '}
              <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                <img src="images/view-answer.svg" alt="" width="21" />
              </figure>
            </button>
          )}
          ModalContent={props => <SurveyAnswersModal {...props} />}
        />
      </div>
    );
  }
}

export default connect()(withRouter(ClientRiskChart));
