import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class DateTimeType extends React.Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      dateValue:
        Response && Response !== 'Invalid date' ? (Response ? moment(Response).toDate() : '') : '',
      timeValue: Response ? moment(Response).toDate() : null,
    };
  }

  handleChangeDate = dateValue => {
    this.setState({
      dateValue: dateValue,
    });
    this.updateValue(dateValue);
  };

  updateValue = value => {
    const { setValue, scope, survey, level, subLevel, subCatLevel, index } = this.props;
    const { SurveyQuestionId } = survey;
    setValue.call(scope, {
      response: value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null,
      surveyQuestionId: SurveyQuestionId,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: index,
    });
  };

  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;

    const ExampleCustomInput = ({ value, onClick }) => {
      return (
        <input
          type="text"
          className="form-control datepicker"
          placeholder="Select Date"
          defaultValue={value}
          onClick={onClick}
        />
      );
    };

    return (
      <div className="type-border col-sm-12 col-lg-6">
        <div>
          <SurveyQuestion survey={survey} ClassName={'mb-0'} />

          <div className="form-group d-flex align-items-center" style={{ maxWidth: '500px' }}>
            <DatePicker
              showYearDropdown
              showTimeSelect
              disabled={isReadOnly}
              selected={this.state.dateValue}
              placeholderText="Select Date"
              dateFormat="dd-MMM-yyyy h:mm aa"
              isClearable
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              onChange={dateValue => {
                this.handleChangeDate(dateValue);
              }}
              popperProps={{
                positionFixed: true,
              }}
            />
          </div>
          <DoesNotApplyOption surveyProps={this.props} />
        </div>
      </div>
    );
  }
}

export default DateTimeType;
