import React, { useState, useEffect } from 'react';
import { Collapse, Button, Popover } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { currencyFormatter, numberFormat } from '../../../../../../util/formatter';
const { Panel } = Collapse;

const NewCostSummary = props => {
  const { existingRows, serviceCodeBundle } = props;

  const [lastExistingRows, setLastExistingRows] = useState([]);
  const [bundlesInContract, setBundlesInContract] = useState([]);
  const [bundleSummaryArray, setBundleSummaryArray] = useState([]);
  const [totalBillingRate, setTotalBillingRate] = useState(null);

  const calAvgOverheadPercentage = serviceCodeArray => {
    //Cal total no of services in bundle
    let NoOfServicesArray =
      serviceCodeArray.length > 0 && serviceCodeArray.map(item => item.NoOfServices);

    let countOfServices =
      NoOfServicesArray.length > 0 &&
      NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);

    //Cal sum of overhead percentage for all service code inclunded in bundle.
    let serviceLevelOverHeadCostPercentageArray = serviceCodeArray.map(
      item => item.NoOfServices * item.OverHeadCostPercentage,
    );

    let sumOfServiceLevelOverHeadCostPercentageArray =
      serviceLevelOverHeadCostPercentageArray.length > 0 &&
      serviceLevelOverHeadCostPercentageArray.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );

    // Cal avg overhead percentage for the bundle.
    let AvgOverheadPercentage = sumOfServiceLevelOverHeadCostPercentageArray / countOfServices;
    if (isNaN(parseFloat(AvgOverheadPercentage.toFixed(2)))) {
      return 0;
    } else {
      return parseFloat(AvgOverheadPercentage.toFixed(2));
    }
  };

  const calAvgOverheadValue = serviceCodeArray => {
    //Cal total no of services in bundle
    let NoOfServicesArray = serviceCodeArray.map(item => item.NoOfServices);

    let countOfServices =
      NoOfServicesArray.length > 0 &&
      NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);
    //Cal sum of overhead percentage for all service code inclunded in bundle.
    let serviceLevelOverHeadCostArray = serviceCodeArray.map(
      item => item.NoOfServices * item.OverHeadCost,
    );
    let sumOfServiceLevelOverHeadCostArray =
      serviceLevelOverHeadCostArray.length > 0
        ? serviceLevelOverHeadCostArray.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
          )
        : 0;
    // Cal avg overhead percentage for the bundle.
    // let AvgOverheadCost = sumOfServiceLevelOverHeadCostArray / countOfServices;

    // Ticket 5756=>Commenting above code because now requirement got change to show total.
    //this is not average anymore but i am not changing the name of the variable.

    let AvgOverheadCost = sumOfServiceLevelOverHeadCostArray;

    if (isNaN(parseFloat(AvgOverheadCost.toFixed(2)))) {
      return 0;
    } else {
      return parseFloat(AvgOverheadCost.toFixed(2));
    }
  };

  const calAvgNetMarginPercentage = serviceCodeArray => {
    //Cal total no of services in bundle
    let NoOfServicesArray = serviceCodeArray.map(item => item.NoOfServices);

    let countOfServices =
      NoOfServicesArray.length > 0 &&
      NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);

    //Cal sum of overhead percentage for all service code inclunded in bundle.
    let serviceLevelNetMarginPercentageArray = serviceCodeArray.map(
      item => item.NoOfServices * item.NetMargin,
    );

    let sumOfServiceLevelNetMarginPercentageArray =
      serviceLevelNetMarginPercentageArray.length > 0 &&
      serviceLevelNetMarginPercentageArray.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );

    // Cal avg overhead percentage for the bundle.
    let AvgNetMarginPercentage = sumOfServiceLevelNetMarginPercentageArray / countOfServices;

    if (isNaN(parseFloat(AvgNetMarginPercentage.toFixed(2)))) {
      return 0;
    } else {
      return parseFloat(AvgNetMarginPercentage.toFixed(2));
    }
  };

  // const calAvgNetMarginValue = serviceCodeArray => {
  //   //Cal total no of services in bundle
  //   let NoOfServicesArray = serviceCodeArray.map(item => item.NoOfServices);

  //   let countOfServices =
  //     NoOfServicesArray.length > 0 &&
  //     NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);

  //   //Cal sum of overhead percentage for all service code inclunded in bundle.
  //   let serviceLevelStandardCostArray = serviceCodeArray.map(
  //     item => item.NoOfServices * item.StandardCost,
  //   );

  //   let sumOfServiceLevelStandardCostArray =
  //     serviceLevelStandardCostArray.length > 0 &&
  //     serviceLevelStandardCostArray.reduce(
  //       (previousValue, currentValue) => previousValue + currentValue,
  //     );

  //   // Cal avg overhead percentage for the bundle.
  //   let AvgNetMarginValue =
  //     (sumOfServiceLevelStandardCostArray / countOfServices) *
  //     parseFloat(calAvgNetMarginPercentage(serviceCodeArray) / 100);

  //   if (isNaN(parseFloat(AvgNetMarginValue.toFixed(2)))) {
  //     return 0;
  //   } else {
  //     return parseFloat(AvgNetMarginValue.toFixed(2));
  //   }
  // };

    const calAvgNetMarginValue = serviceCodeArray => {
      //Cal total no of services in bundle
      let NoOfServicesArray = serviceCodeArray.map(item => item.NoOfServices);

      let countOfServices =
        NoOfServicesArray.length > 0 &&
        NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);

      //Cal sum of overhead percentage for all service code inclunded in bundle.
      let serviceLevelStandardCostArray = serviceCodeArray.map(
        item => item.NoOfServices * item.StandardCost,
      );
      let sumOfServiceLevelStandardCostArray =
        serviceLevelStandardCostArray.length > 0
          ? serviceLevelStandardCostArray.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
            )
          : 0;

      // Cal avg NetMargin percentage for the bundle.
      // let AvgNetMarginValue =
      //   (sumOfServiceLevelStandardCostArray / countOfServices) *
      //   parseFloat(calAvgNetMarginPercentage(serviceCodeArray) / 100);

      // Ticket 5756=>Commenting above code because now requirement got change to show total.
      //this is not average anymore but i am not changing the name of the variable.

      let serviceLevelNetMarginCostArray = serviceCodeArray.map(item => {
        if (item.hasOwnProperty('NetMarginCost')) {
          return item.NetMarginCost * item.NoOfServices;
        } else {
          return item.NetAmount * item.NoOfServices;
        }
      });

      let AvgNetMarginValue =
        serviceLevelNetMarginCostArray.length > 0
          ? serviceLevelNetMarginCostArray.reduce(
              (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue),
            )
          : 0;
      if (isNaN(parseFloat(AvgNetMarginValue && AvgNetMarginValue.toFixed(2)))) {
        return 0;
      } else {
        return parseFloat(AvgNetMarginValue && AvgNetMarginValue.toFixed(2));
      }
  };
  

  const calBundleBillingRate = serviceCodeArray => {
    let BillingRateArray = serviceCodeArray.map(item => {
      if (item.TotalServiceCost) {
        return item.NoOfServices * parseFloat(item.TotalServiceCost);
      } else {
        return item.NoOfServices * parseFloat(item.BillingRate ? item.BillingRate : 0);
      }
    });

    let sumOfAllBillingRate =
      BillingRateArray.length > 0 &&
      BillingRateArray.reduce((previousValue, currentValue) => previousValue + currentValue);

    return sumOfAllBillingRate && parseFloat(sumOfAllBillingRate);
  };

  const calCostSummary = (bundle, index) => {
    if (bundle) {
      let bundleDetails = [];
      bundle.ServiceType &&
        bundle.ServiceType.map(itemA => {
          if (itemA && itemA.BuildServiceBundle) {
            bundleDetails = [...bundleDetails, ...itemA.BuildServiceBundle];
          }
        });

      let isAllBundleDefind =
        bundleDetails &&
        bundleDetails.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });
      if (isAllBundleDefind) {
        let tempAvgOverHeadPercentage = calAvgOverheadPercentage(bundleDetails);
        let tempAvgOverHeadValue = calAvgOverheadValue(bundleDetails);
        let tempAvgNetMarginPercentage = calAvgNetMarginPercentage(bundleDetails);
        let tempAvgNetMarginValue = calAvgNetMarginValue(bundleDetails);
        let tempBundleBillingRate = calBundleBillingRate(bundleDetails);
        return {
          serviceCodeBundleId: bundle.ServiceCodeBundleId ? bundle.ServiceCodeBundleId : null,
          daysInTreatment: bundle.Duration ? bundle.Duration : null,
          serviceCodeBundleName: bundle.ServiceCodeBundleName ? bundle.ServiceCodeBundleName : null,
          avgOverHeadPercentage: tempAvgOverHeadPercentage,
          avgOverHeadValue: tempAvgOverHeadValue,
          avgNetMarginPercentage: tempAvgNetMarginPercentage,
          avgNetMarginValue: tempAvgNetMarginValue,
          bundleBillingRate: tempBundleBillingRate,
        };
      }
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (existingRows && JSON.stringify(existingRows) != JSON.stringify(lastExistingRows)) {
      let isExistingDefind =
        existingRows &&
        existingRows.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });
      if (isExistingDefind) {
        // as Goals are removed as top category we are getting bundles directly.
        // let bundlesInContract = [];
        // existingRows &&
        //   existingRows.map(itemA => {
        //     if (itemA && itemA.Bundles) {
        //       bundlesInContract = [...bundlesInContract, ...itemA.Bundles];
        //     }
        //   });
        // setBundlesInContract(bundlesInContract);
        setBundlesInContract(existingRows);
        setLastExistingRows(existingRows);
      }
    }
  }, [existingRows]);

  useEffect(() => {
    if (bundlesInContract && bundlesInContract.length > 0) {
      let isExistingDefind =
        bundlesInContract &&
        bundlesInContract.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });

      if (isExistingDefind) {
        let xyz =
          bundlesInContract &&
          bundlesInContract.map((item, index) => {
            return calCostSummary(item, index);
          });
        let tempTotalBillingRate =
          xyz &&
          xyz
            .map(item => {
              if (item && item.bundleBillingRate) {
                return item.bundleBillingRate;
              } else {
                return 0;
              }
            })
            .reduce((previousValue, currentValue) => {
              return previousValue + currentValue;
            });

        setBundleSummaryArray(xyz); 
        props.setGetBundleSummaryArray(xyz);
        setTotalBillingRate(tempTotalBillingRate);
      }
    }
  }, [bundlesInContract]);

  useEffect(() => {
    return () => {
      setBundleSummaryArray([]);
      setTotalBillingRate(null);
    };
  }, []);
  return (
    <div className="payer-details p-2 cost-summary" style={{ position: 'sticky', top: '0' }}>
      <div className="label mt-2 mb-2">Cost Summary</div>
      <div>
        {bundleSummaryArray.length > 0 &&
        bundleSummaryArray.some(item => {
          if (item && item.bundleBillingRate) {
            return true;
          } else {
            return false;
          }
        }) ? (
          <Collapse
            // defaultActiveKey={['0']}
            expandIcon={({ isActive }) => (
              <RightCircleOutlined
                rotate={isActive ? -90 : 90}
                style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
              />
            )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            // onChange={onChange}
          >
            {bundleSummaryArray.length > 0 ? (
              bundleSummaryArray.map((item, index) => {
                const {
                  daysInTreatment,
                  serviceCodeBundleName,
                  avgOverHeadPercentage,
                  bundleBillingRate,
                  avgNetMarginPercentage,
                  avgNetMarginValue,
                  avgOverHeadValue,
                  serviceCodeBundleId,
                } = item || {};
                let bunName =
                  serviceCodeBundle &&
                  serviceCodeBundle.filter((item, index) => {
                    return item.Value == serviceCodeBundleId;
                  });
                return (
                  <>
                    <Panel
                      showArrow={true}
                      key={index}
                      header={
                        <>
                          <div
                            class=""
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            key={index}
                          >
                            Bundle {index + 1}
                          </div>
                        </>
                      }
                    >
                      <div className="row mt-2 mb-4">
                        <div className="col-md-12 px-2 bold">
                          {bunName && bunName[0] && bunName[0].Text}{' '}
                          {daysInTreatment ? `-${daysInTreatment} Days` : ``}
                        </div>
                      </div>
                      <div className="row mt-2 mb-4">
                        <div className="col-md-8 px-2">Average Overhead %</div>
                        <div className="col-md-4 px-2 money text-right">
                          {avgOverHeadPercentage}%
                        </div>
                      </div>
                      <div className="row mt-2 mb-4">
                        <div className="col-md-8 px-2">Total Overhead $</div>
                        {/* <div className="col-md-4 px-2 money text-right">{avgOverHeadValue}</div> */}
                        <div className="col-md-4 px-2 money text-right">
                          {' '}
                          {!isNaN(avgOverHeadValue)
                            ? currencyFormatter.format(avgOverHeadValue || 0)
                            : '$0'}
                        </div>
                      </div>
                      <div className="row mt-2 mb-4">
                        <div className="col-md-8 px-2">Average Net Margin %</div>
                        <div className="col-md-4 px-2 money text-right">
                          {avgNetMarginPercentage}%
                        </div>
                      </div>
                      <div className="row mt-2 mb-4">
                        <div className="col-md-8 px-2">Total Net Margin $</div>
                        {/* <div className="col-md-4 px-2 money text-right">{avgNetMarginValue}</div> */}
                        <div className="col-md-4 px-2 money text-right">
                          {' '}
                          {!isNaN(avgNetMarginValue)
                            ? currencyFormatter.format(avgNetMarginValue || 0)
                            : '$0'}
                        </div>
                      </div>
                      <div className="row mt-2 mb-4">
                        <div className="col-md-8 px-2">Billing Rate</div>
                        <div className="col-md-4 px-2 money text-right">
                          {bundleBillingRate
                            ? currencyFormatter.format(bundleBillingRate || 0)
                            : ''}
                        </div>
                      </div>
                    </Panel>
                  </>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
        ) : (
          'Please complete the selection of bundles, service types or service codes to check the summary.'
        )}
      </div>

      <div className="row">
        <div className="col-md-12"></div>
      </div>
      {totalBillingRate ? (
        <div className="row mt-4 mb-4">
          <div className="col-md-8 mt-2 mb-2 bold h4">Total Contract Cost</div>
          <div className="col-md-4 mt-2 money text-right">
            {totalBillingRate ? currencyFormatter.format(totalBillingRate || 0) : ''}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default NewCostSummary;
