import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class SmallText extends React.Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      value: Response,
    };
  }
  onChange = (event, click) => {
    const { value } = event.target;
    this.setState({
      value: value,
    });
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      IsRequired,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    return (
      <>
        <div
          // className="card border-0"
          className="type-border col-sm-6 col-lg-4"
        >
          <div
          // class="card-body"
          >
            <div class="answer-options text-left">
              <div class="quantity-btn">
                <div class="numbers-row">
                  <input
                    disabled={isReadOnly}
                    type="text"
                    value={this.state.value}
                    tabindex="0"
                    maxlength="3"
                    style={{
                      textAlign: 'center',
                      padding: '2px',
                      maxWidth: '45px',
                      border: '1px solid gray',
                      minHeight: '30px',
                      marginRight: '5px',
                    }}
                    className="alphanumeric-type"
                    onChange={this.onChange}
                    onBlur={() => {
                      setValue.call(scope, {
                        response: this.state.value,
                        surveyQuestionId: SurveyQuestionId,
                        level: level,
                        subLevel: subLevel,
                        subCatLevel: subCatLevel,
                        index: index,
                      });
                    }}
                  />
                </div>
                {Question && Question.length > 0 && (
                  <span className="survey-question rcm-question text-left">
                    {renderHTML(Question)}
                    {IsRequired && <span className="text-danger">*</span>}
                  </span>
                )}
              </div>
            </div>
            <DoesNotApplyOption surveyProps={this.props} />
          </div>
        </div>
      </>
    );
  }
}
export default SmallText;
